import { AxiosPromise } from 'axios';
import { StorageGraphData, StorageData } from '@/types';
import { Core, STORAGE, STORAGE_GRAPH } from '../Core';

type GetStorage = () => AxiosPromise<StorageData>;
type GetGraph = () => AxiosPromise<StorageGraphData>;

class Storage extends Core {
  getStorage: GetStorage = async () => this.get(STORAGE());

  getGraph: GetGraph = async () => this.get(STORAGE_GRAPH());
}

export const storage = new Storage();
