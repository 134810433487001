import { CreateServiceUnit, UpdateServiceUnit, ServiceUnit } from '@/types';

export const formatUpdateCompanyData = (
  newData: Partial<ServiceUnit>,
  oldData: ServiceUnit,
): UpdateServiceUnit => ({
  id: oldData.id,
  companyId: {
    isChanged: newData.company?.id !== undefined,
    value:
      newData.company?.id === null
        ? newData.company?.id
        : oldData.company?.id ?? null,
  },
  name: {
    isChanged: newData.name !== undefined,
    value: newData.name !== undefined ? newData.name : oldData.name,
  },
  countryId: {
    isChanged: newData.country?.id !== undefined,
    value:
      newData.country?.id !== undefined
        ? newData.country?.id
        : oldData.country?.id ?? null,
  },
  isActive: {
    isChanged: newData.isActive !== undefined,
    value: newData.isActive !== undefined ? newData.isActive : oldData.isActive,
  },
  imo: {
    isChanged: newData.imo !== undefined,
    value: newData.imo !== undefined ? newData.imo : oldData.imo,
  },
  mmsi: {
    isChanged: newData.mmsi !== undefined,
    value: newData.mmsi !== undefined ? newData.mmsi : oldData.mmsi,
  },
  ref: {
    isChanged: newData.ref !== undefined,
    value: newData.ref !== undefined ? newData.ref : oldData.ref,
  },
  serviceProviderId: {
    isChanged: newData.serviceProvider?.id !== undefined,
    value:
      newData.serviceProvider?.id !== undefined
        ? newData.serviceProvider?.id
        : oldData.serviceProvider?.id ?? null,
  },
  serviceUnitTypeId: {
    isChanged: newData.serviceUnitType?.id !== undefined,
    value:
      newData.serviceUnitType?.id !== undefined
        ? newData.serviceUnitType?.id
        : oldData.serviceUnitType?.id ?? null,
  },
});

export const formatCreateCompanyData = (
  data: ServiceUnit,
): CreateServiceUnit => ({
  companyId: data.company?.id ?? null,
  imo: data.imo,
  mmsi: data.mmsi,
  name: data.name,
  ref: data.ref,
  serviceProviderId: data.serviceProvider?.id ?? null,
  serviceUnitTypeId: data.serviceUnitType?.id ?? null,
  countryId: data.country?.id ?? null,
  isActive: data.isActive ?? false,
});
