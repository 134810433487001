import { useMsal } from '@azure/msal-react';
import { AppFeatures, AppRoles } from '@/types';

type IdTokenClaims =
  | {
      tenantColor1: string;
      tenantColor2: string;
      tenantIcon: string;
      roles: AppRoles[];
      allTenants: string[];
      appTenantId: string;
      appTenantName: string;
      acr: string;
      featuresDisabled: AppFeatures[];
      signInName?: string;
      userDisplayName?: string;
    }
  | undefined;

export const useIdTokenClaims = () => {
  const { instance } = useMsal();

  return instance.getAllAccounts()[0]?.idTokenClaims as IdTokenClaims;
};
