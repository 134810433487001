import React from 'react';
import { Col, Row } from '@/ui';
import { Icon } from '@/ui/components/Icon';
import { useNavigation } from '@/ui/components/Navigation/hooks';
import { useTheme } from 'styled-components';
import { NavigationGridLinkItem } from './NavigationGrid.styled';

export const NavigationGrid = () => {
  const { pages } = useNavigation();
  const { defaultLink } = useTheme();

  return (
    <Row>
      {pages.systemAdministrationPages.map((page) => (
        <Col key={page.label}>
          <NavigationGridLinkItem to={page.to}>
            <Icon icon={page.icon} scale={5} color={defaultLink} />
            <p>{page.label}</p>
          </NavigationGridLinkItem>
        </Col>
      ))}
    </Row>
  );
};
