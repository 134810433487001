import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { QueryClientProvider } from 'react-query';
import { Normalize } from 'styled-normalize';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { msalInstance } from '@/auth';
import { queryClient } from '@/config/reactQuery';
import { TranslationsProvider } from '@/translations';
import { GlobalStyle } from '@/ui/globalStyles';

import { AppThemeProvider } from './providers/AppThemeProvider';
import { HttpInterceptor } from './providers/HttpInterceptor';
import { App } from './App';

export const AppWithProviders = () => (
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AppThemeProvider>
        <GlobalStyle />
        <QueryClientProvider client={queryClient}>
          <TranslationsProvider>
            <HttpInterceptor>
              <Normalize />
              <BrowserRouter>
                <App />
              </BrowserRouter>
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
            </HttpInterceptor>
          </TranslationsProvider>
        </QueryClientProvider>
      </AppThemeProvider>
    </MsalProvider>
  </React.StrictMode>
);
