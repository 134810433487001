import React, { FC } from 'react';
import { useTranslate } from '@/translations';
import {
  HomeBanner,
  HomeBannerContent,
  HomeBannerInfo,
  HomeBannerInfoTitle,
  HomeBannerInfoDescription,
} from './Home.styled';

export const Home: FC = () => {
  const { t } = useTranslate();

  return (
    <HomeBanner fluid data-testid="home-page">
      <HomeBannerContent>
        <HomeBannerInfo sm>
          <HomeBannerInfoTitle>{t('loginBannerInfoTitle')}</HomeBannerInfoTitle>
          <HomeBannerInfoDescription>
            {t('loginBannerInfoDescription')}
          </HomeBannerInfoDescription>
        </HomeBannerInfo>
      </HomeBannerContent>
    </HomeBanner>
  );
};
