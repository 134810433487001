import React, { FC, useState } from 'react';
import { Col, DateBox, SelectBox } from '@/ui';
import { useTranslate } from '@/translations';
import { Button } from '@/ui/components/Button';
import { formatDate } from '@/modules/Vessel/utils';
import { FORMAT_DATE } from '@/utils/constants';
import { TransportMovementFiltersRow } from './TransportMovementFilters.styled';
import { TransportMovementFiltersProps } from './types';

export const TransportMovementFilters: FC<TransportMovementFiltersProps> = ({
  initData,
  onChange,
}) => {
  const { t } = useTranslate();
  const [locationFrom, setLocationFrom] = useState<string | null>(null);
  const [locationTo, setLocationTo] = useState<string | null>(null);
  const [despatchDate, setDespatchDate] = useState<string | undefined>(
    undefined,
  );

  const handleFilterChange = () => {
    onChange({
      locationFrom,
      locationTo,
      despatchDate: formatDate(despatchDate),
    });
  };

  return (
    <TransportMovementFiltersRow>
      <Col lg={3} md={4} sm={6} className="mb-3 mb-sm-2 mb-lg-0">
        <SelectBox
          items={initData.locationFromItems}
          value={locationFrom}
          placeholder={t('chooseLocation')}
          label={t('fromLocation')}
          onValueChange={(e) => setLocationFrom(e)}
          showClearButton
        />
      </Col>
      <Col lg={3} md={4} sm={6} className="mb-3 mb-sm-2 mb-lg-0">
        <SelectBox
          items={initData.locationToItems}
          value={locationTo}
          placeholder={t('chooseLocation')}
          label={t('toLocation')}
          onValueChange={(e) => setLocationTo(e)}
          showClearButton
        />
      </Col>
      <Col lg={3} md={4} sm={6} className="mb-3 mb-sm-2 mb-lg-0">
        <DateBox
          applyValueMode="useButtons"
          value={despatchDate}
          placeholder={t('chooseDate')}
          label={t('despatchDate')}
          onValueChange={(e) => setDespatchDate(e)}
          showClearButton
          displayFormat={FORMAT_DATE}
        />
      </Col>
      <Col lg={3} md={12} sm={12}>
        <Button fullWidth="true" title={t('go')} onClick={handleFilterChange} />
      </Col>
    </TransportMovementFiltersRow>
  );
};
