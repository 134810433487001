import styled from 'styled-components';
import { Link } from 'react-router-dom';

type NavLinkProps = {
  color: string;
  fontSize?: string;
};

export const NavLogo = styled(Link)`
  img {
    @media (max-width: 1439px) {
      max-width: 100px;
      height: auto;
    }
  }
`;

export const NavLinkDesktop = styled.span<NavLinkProps>`
  font-size: ${({ fontSize }) => fontSize || '40px'};
  line-height: 1;
  padding: 8px;
  color: ${(props) => props.color};
  cursor: pointer;

  &:hover {
    color: #f5871f;
  }

  @media (max-width: 1439px) {
    font-size: 26px;
    padding: 5px;
  }
`;

export const NavLinkMobile = styled.span<NavLinkProps>`
  display: flex;
  font-size: 20px;
  color: ${(props) => props.color};
  border-top: 1px solid ${({ theme }) => theme.defaultLink};
  padding: 10px 5px;
`;

export const NavLinkLabelMobile = styled.div`
  margin-left: 10px;
  color: ${({ theme }) => theme.defaultLink};
  font-size: 18px;
`;

export const NavLinkContent = styled(Link)`
  text-decoration: none;
`;

export const NavCustomerLogo = styled.img`
  max-height: 56px;
  max-width: 167px;
  align-self: center;
  padding: 8px;

  @media (max-width: 1320px) {
    max-width: 120px;
    max-height: 36px;
    padding: 5px;
  }
`;
