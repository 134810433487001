import { AppEntities } from '@/types';
import { UploadDocumentModalProps } from '@/ui/components/UploadDocumentModal/types';

export const getInitialValuesFromSearchParams = (searchParams: URLSearchParams) => {
  const initialValues: UploadDocumentModalProps['initialValues'] = {};

  const entity = searchParams.get('entity') as AppEntities;

  if (entity) {
    initialValues.entity = entity;
  }

  const entityReference = searchParams.get('entityReference');

  if (entityReference) {
    initialValues.entityReference = entityReference;
  }

  return initialValues;
};
