import React, { FC } from 'react';
import { Title } from '@/ui/components/UploadDocumentModal/UploadDocumentModal.styled';
import { AuthorizationStatus } from '@/modules/Inspection/components/tabs/Damage/components/DamageDataGrid/types';
import { DevButton, Popup, RequiredRule, TextArea } from '@/ui';
import { useTranslate } from '@/translations';
import { ActionsPopupProps } from './types';

export const ActionsPopup: FC<ActionsPopupProps> = ({
  isPopupVisible,
  togglePopup,
  comment,
  setComment,
  handleSaveClick,
  handleCancelClick,
  authorizationStatus,
}) => {
  const { t } = useTranslate();

  return (
    <Popup
      visible={isPopupVisible}
      onHiding={togglePopup}
      showTitle={false}
      position="center"
      minWidth="320"
      maxWidth="440"
      height="auto"
    >
      <Title>
        {t(
          authorizationStatus === AuthorizationStatus.Approved
            ? 'approveStatusQuestion'
            : 'rejectStatusQuestion',
        )}
      </Title>
      <TextArea
        className="text-wrap"
        value={comment}
        autoResizeEnabled
        valueChangeEvent="keyup"
        onValueChanged={({ value }) => setComment(value)}
        placeholder={t('addNote')}
      >
        <RequiredRule />
      </TextArea>

      <div className="mt-3 d-flex gap-2 justify-content-end">
        <DevButton
          text={t('yes')}
          icon="save"
          onClick={handleSaveClick}
          disabled={
            !comment && authorizationStatus === AuthorizationStatus.Rejected
          }
        />
        <DevButton
          text={t('cancel')}
          icon="clear"
          onClick={handleCancelClick}
        />
      </div>
    </Popup>
  );
};
