import { LogLevel, Configuration } from '@azure/msal-browser';

export const deployment = {
  policyPrefix: process.env.REACT_APP_POLICY_PREFIX,
  b2cTenantName: process.env.REACT_APP_TENANT_NAME,
  b2cTenantId: process.env.REACT_APP_TENANT_ID,
  b2cClientId: process.env.REACT_APP_CLIENT_ID || '',
  restUrl: process.env.REACT_APP_BASE_API_URL,
};

export const policyNames = {
  signIn: `b2c_1a_${deployment.policyPrefix}signIn`,
  newTenant: `b2c_1a_${deployment.policyPrefix}newTenant`,
  signUPIn: `B2C_1_SignIn_${deployment.policyPrefix}`
};

export const b2cPolicies = {
  authorities: {
    signIn: {
      authority: `https://${deployment.b2cTenantName}.b2clogin.com/${deployment.b2cTenantId}/${policyNames.signIn}`,
    },
  },
  authorityDomain: `${deployment.b2cTenantName}.b2clogin.com`,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: deployment.b2cClientId, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Use a sign-up/sign-in user-flow as a default authority
    knownAuthorities: [b2cPolicies.authorityDomain, 'http://localhost:3000/'], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }

        if (LogLevel.Error === level) {
          // eslint-disable-next-line no-console
          console.error(message);
        }

        if (LogLevel.Info === level) {
          // eslint-disable-next-line no-console
          console.info(message);
        }

        if (LogLevel.Verbose === level) {
          // eslint-disable-next-line no-console
          console.debug(message);
        }

        if (LogLevel.Warning === level) {
          // eslint-disable-next-line no-console
          console.warn(message);
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [
    'openid',
    'profile',
    'https://mrochonb2cprod.onmicrosoft.com/mtrest/User.Invite',
    'https://mrochonb2cprod.onmicrosoft.com/mtrest/User.ReadAll',
  ],
};

export const silentRequest = {
  scopes: [
    'openid',
    'profile',
    'https://mrochonb2cprod.onmicrosoft.com/mtrest/User.Invite',
    'https://mrochonb2cprod.onmicrosoft.com/mtrest/User.ReadAll',
  ],
};
