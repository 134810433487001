import { useMutation, useQuery } from 'react-query';
import { queryClient } from '@/config/reactQuery';
import { QUERY_KEYS } from '../../queryKeys';
import { documentManagement } from '../DocumentManagement';

export const useDocuments = () =>
  useQuery(
    QUERY_KEYS.documentManagementDocument,
    async () => (await documentManagement.getDocuments()).data,
  );

export const useUploadDocument = () =>
  useMutation(documentManagement.uploadDocument, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        QUERY_KEYS.documentManagementDocument,
      );
    },
  });

export const useDeleteDocument = () =>
  useMutation(documentManagement.deleteDocument, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.documentManagementDocument);
    },
  });
