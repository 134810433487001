import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { queryClient } from '@/config/reactQuery';
import { partCodeValidator } from '@/utils/validations';
import { FrCodeData, GetInspectionsData } from '@/types';

import { notify } from '@/ui';
import { QUERY_KEYS } from '../../queryKeys';
import { inspections } from '../Inspections';

export const useInspectionsReportsInitial = (
  inspectionId: number,
) =>
  useQuery(
    QUERY_KEYS.inspectionsReportsInitial,
    async () =>
      (await inspections.getInspectionsReportsInitial(inspectionId)).data,
    { enabled: !!inspectionId },
  );

export const useInspectionsVehicles = () =>
  useQuery(
    QUERY_KEYS.inspectionsVehicles,
    async () => (await inspections.getInspectionsVehicles()).data,
  );

export const usePostInspectionsVehicles = (data: GetInspectionsData) =>
  useQuery(
    QUERY_KEYS.inspectionsVehiclesTemp,
    async () => (await inspections.postInspectionsVehicles(data)).data,
  );

export const useUpdateInspectionsVehicle = (id: number) =>
  useMutation(inspections.updateInspectionsVehicle, {
    onSuccess: async ({ data }) => {
      const { message, result } = data?.entities?.[0] || {};

      if (result === 'Failed') {
        notify(
          {
            message,
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          'error',
          10000,
        );
      } else {
        await queryClient.invalidateQueries([
          QUERY_KEYS.inspectionsVehicle,
          id,
        ]);
      }
    },
    onError: async ({ message }) => {
      notify(
        {
          message,
          position: {
            my: 'center top',
            at: 'center top',
          },
        },
        'error',
        10000,
      );
    },
  });

export const useInspectionsVehicle = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsVehicle, id],
    async () => (await inspections.getInspectionsVehicle(id)).data,
  );

export const useInspectionsVehicleConditions = () =>
  useQuery(
    QUERY_KEYS.conditions,
    async () => (await inspections.getInspectionsVehicleConditions()).data,
  );

export const useInspectionsVehicleDamages = (id: number) =>
  useQuery(
    [QUERY_KEYS.damages, id],
    async () => (await inspections.getInspectionsVehicleDamages(id)).data,
  );

export const useUpdateInspectionsVehicleDamages = () =>
  useMutation(inspections.updateInspectionsVehicleDamage, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.damages]);
      await queryClient.invalidateQueries([QUERY_KEYS.inspectionsVehicle]);
    },
  });

export const useUpdateInspectionsVehicleDamageActions = () =>
  useMutation(inspections.updateInspectionsVehicleDamageActions);

export const useUpdateInspectionsVehicleDamageParts = () =>
  useMutation(inspections.updateInspectionsVehicleDamageParts, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.inspectionsVehicle]);
      await queryClient.invalidateQueries([QUERY_KEYS.damages]);
    },
  });

export const useInspectionsVehicleSeverityActions = (
  damagePartCode: string,
  damageTypeCode: string,
) =>
  useQuery(
    QUERY_KEYS.severityActions,
    async () =>
      (
        await inspections.getInspectionsVehicleSeverityActions(
          damagePartCode,
          damageTypeCode,
        )
      ).data,
    {
      enabled: !!damagePartCode && !!damageTypeCode,
    },
  );

export const useInspectionsVehicleRepairActions = () =>
  useQuery(
    QUERY_KEYS.repairActions,
    async () => (await inspections.getInspectionsVehicleRepairActions()).data,
  );

export const useInspectionsVehiclesParts = (partNumber: string) =>
  useQuery(
    [QUERY_KEYS.inspectionsVehiclesParts, partNumber],
    async () =>
      (await inspections.getInspectionsVehiclesParts(partNumber)).data,
    {
      enabled: partCodeValidator(partNumber),
    },
  );

export const useInspectionsVehiclesDamageActionsByFlatRateCode = (
  frCode: string,
  options?:
    | Omit<
        UseQueryOptions<FrCodeData, unknown, FrCodeData, string[]>,
        'queryKey' | 'queryFn'
      >
    | undefined,
) =>
  useQuery(
    [QUERY_KEYS.inspectionsVehiclesPartsByFrCode, frCode],
    async () =>
      (
        await inspections.getInspectionsVehiclesDamageActionsByFlatRateCode(
          frCode,
        )
      ).data,
    options,
  );

export const useInspectionsVehicleDamageActions = (id: number) =>
  useQuery(
    [QUERY_KEYS.damageActions, id],
    async () => (await inspections.getInspectionsVehicleDamageActions(id)).data,
    { staleTime: Infinity },
  );

export const useInspectionsVehicleDamageParts = (id: number) =>
  useQuery(
    [QUERY_KEYS.damageParts, id],
    async () => (await inspections.getInspectionsVehicleDamageParts(id)).data,
  );

export const useDeleteInspectionsVehicleDamageAction = () =>
  useMutation(inspections.deleteInspectionsVehiclesDamageAction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.inspectionsVehicle]);
      await queryClient.invalidateQueries([QUERY_KEYS.damages]);
      await queryClient.invalidateQueries([QUERY_KEYS.damageActions]);
    },
  });

export const useDeleteInspectionsVehicleDamagePart = () =>
  useMutation(inspections.deleteInspectionsVehiclesDamagePart, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.inspectionsVehicle]);
      await queryClient.invalidateQueries([QUERY_KEYS.damages]);
      await queryClient.invalidateQueries([QUERY_KEYS.damageParts]);
    },
  });

export const useCreateInspectionsVehiclesFlatRateCode = () =>
  useMutation(inspections.createInspectionsVehiclesFlatRateCode);

export const useInspectionsVehiclesInspectors = (options?: {
  enabled?: boolean;
}) =>
  useQuery(
    QUERY_KEYS.inspectionsVehiclesInspectors,
    async () => (await inspections.getInspectionsVehiclesInspectors()).data,
    options,
  );

export const useInspectionsVehiclesInspector = (
  id: number,
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery(
    QUERY_KEYS.inspectionsVehiclesInspectors,
    async () => (await inspections.getInspectionsVehiclesInspector(id)).data,
    options,
  );

export const useInspectionsVehiclesNotes = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsVehiclesNotes, id],
    async () => (await inspections.getInspectionsVehiclesNotes(id)).data,
  );

export const useUpdateInspectionsVehiclesNotes = (id: number) =>
  useMutation(inspections.updateInspectionsVehiclesNotes, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        QUERY_KEYS.inspectionsVehiclesNotes,
        id,
      ]);
    },
  });

export const useInspectionsLogs = () =>
  useQuery(
    QUERY_KEYS.logs,
    async () => (await inspections.getInspectionsLogs()).data,
  );

export const useInspectionsChecklistDynamicOperations = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsChecklistDynamicOperations, id],
    async () =>
      (await inspections.getInspectionsChecklistDynamicOperations(id)).data,
  );

export const useInspectionsChecklistElectricHybrid = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsChecklistElectricHybrid, id],
    async () =>
      (await inspections.getInspectionsChecklistElectricHybrid(id)).data,
  );

export const useInspectionsChecklistEngineRunning = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsChecklistEngineRunning, id],
    async () =>
      (await inspections.getInspectionsChecklistEngineRunning(id)).data,
  );

export const useInspectionsChecklistInterior = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsChecklistInterior, id],
    async () => (await inspections.getInspectionsChecklistInterior(id)).data,
  );

export const useInspectionDamagesImages = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionDamageImage, id],
    async () => (await inspections.getInspectionsDamagesImages(id)).data,
    { staleTime: Infinity },
  );

export const useUpdateInspectionDamagesImages = (id: number) =>
  useMutation(inspections.uploadInspectionsDamagesImages, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        QUERY_KEYS.inspectionDamageImage,
        id,
      ]);
    },
  });

export const useDeleteInspectionDamagesImages = () =>
  useMutation(inspections.deleteInspectionsDamagesImage, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.inspectionDamageImage]);
    },
  });

export const useInspectionsVehiclesSummaries = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsVehiclesSummaries, id],
    async () => (await inspections.getInspectionsVehiclesSummaries(id)).data,
  );

export const useInspectionsVehiclesMechanicalsNotes = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsMechanicalsNotes, id],
    async () =>
      (await inspections.getInspectionsVehiclesMechanicalsNotes(id)).data,
  );

export const useInspectionsMechanicalsWheels = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsMechanicalsWheels, id],
    async () => (await inspections.getInspectionsMechanicalsWheels(id)).data,
  );
