import React, { FC, useCallback, useMemo } from 'react';
import { isPagerVisible, localDateFormatted } from '@/utils/helpers';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import {
  Column,
  DataGrid,
  LoadPanel,
  Pager,
  Sorting,
  FilterRow,
  Lookup,
  Editing,
  RequiredRule,
} from '@/ui';
import { faEnvelope, Icon } from '@/ui/components/Icon';
import { Status } from '@/types/Users/Users';
import { useTranslate } from '@/translations';
import { GBAUsersTabProps } from './types';

const PAGE_SIZE = 20;

export const GBAUsersTab: FC<GBAUsersTabProps> = ({
  users,
  roles,
  gbaUsers,
  inspectors,
  tenantId,
  onInviteUser,
  onAssignUser,
  onUnassignUser,
}) => {
  const { t } = useTranslate();

  const onRowInserting = useCallback(
    ({ data: { gbaUserId } }) => {
      onAssignUser({ userId: gbaUserId, tenantId });
      // TODO:
      //  1. assign user - done
      //  2. wait Rostyk, make PATCH /api/user/gba - add inspectorId to gbaUser
    },
    [onAssignUser, tenantId],
  );

  const handleUnassignUser = useCallback(
    (id) => {
      onUnassignUser({ userId: id, tenantId });
    },
    [onUnassignUser, tenantId],
  );

  const onRenderInvitationCell = useCallback(
    ({ data }) => {
      if (data.status === Status.active) return null;

      return (
        <StyledLinkContainer>
          <StyledLink onClick={() => onInviteUser(data.id)}>
            <Icon icon={faEnvelope} />
          </StyledLink>
        </StyledLinkContainer>
      );
    },
    [onInviteUser],
  );

  const onRenderUnassignCell = useCallback(
    ({ data: { id } }) => (
      <StyledLinkContainer>
        <StyledLink onClick={() => handleUnassignUser(id)}>
          {t('unassign')}
        </StyledLink>
      </StyledLinkContainer>
    ),
    [handleUnassignUser, t],
  );

  const inspectorsDataSource = useMemo(
    () =>
      inspectors?.map(({ id, inspectorSurname, inspectorForenames }) => ({
        id,
        displayExpr: `${inspectorForenames} ${inspectorSurname}`,
      })),
    [inspectors],
  );

  return (
    <DataGrid
      id="gridContainer"
      columnHidingEnabled
      onRowInserting={onRowInserting}
      showBorders
      dataSource={users}
      rowAlternationEnabled
      width="100%"
    >
      <LoadPanel enabled />
      <Pager
        allowedPageSizes={PAGE_SIZE}
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, users?.length)}
      />
      <Editing
        allowAdding
        mode="form"
        texts={{
          saveRowChanges: t('assign'),
        }}
        popup={{
          width: 400,
          height: 175,
        }}
      />
      <FilterRow visible />
      <Sorting mode="multiple" />
      <Column
        dataField="displayName"
        caption="Username"
        formItem={{ visible: false }}
      />
      <Column
        dataField="email"
        caption="Email"
        width={225}
        formItem={{ visible: false }}
      />
      <Column
        dataField="role.id"
        caption="Role"
        width={100}
        formItem={{ visible: false }}
      >
        <Lookup dataSource={roles} displayExpr="name" valueExpr="id" />
      </Column>
      <Column
        dataField="status"
        caption="Status"
        width={100}
        formItem={{ visible: false }}
      />
      <Column dataField="gbaUserId" caption={t('gbaUser')} visible={false}>
        <Lookup
          dataSource={gbaUsers}
          displayExpr="displayName"
          valueExpr="id"
        />
        <RequiredRule />
      </Column>
      <Column dataField="inspectorId" caption="Inspector">
        <Lookup
          dataSource={inspectorsDataSource}
          displayExpr="displayExpr"
          valueExpr="id"
        />
      </Column>
      <Column
        dataField="lastLogIn"
        caption="Last Log In"
        format={localDateFormatted}
        formItem={{ visible: false }}
      />
      <Column
        caption="Invitation"
        width={100}
        formItem={{ visible: false }}
        allowFiltering={false}
        alignment="center"
        cellRender={onRenderInvitationCell}
      />
      <Column
        caption="Tenant"
        width={100}
        allowFiltering={false}
        formItem={{ visible: false }}
        alignment="center"
        cellRender={onRenderUnassignCell}
      />
    </DataGrid>
  );
};
