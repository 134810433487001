import React, { FC } from 'react';
import {
  Column,
  Export,
  Paging,
  Pager,
  LoadPanel,
  DataGrid,
  MasterDetail,
  Grouping,
} from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { StatusDetailsProps } from './types';
import { FormattedStatusData } from '../../types';
import { PipelineDataGridInner } from '../PipelineDataGridInner';

const PAGE_SIZE = 20;

export const PipelineDataGrid: FC<StatusDetailsProps> = ({
  statusDetailData,
  onSelectStatusGroup,
}) => (
  <DataGrid
    id="gridContainer"
    dataSource={statusDetailData}
    keyExpr={['statusCode', 'statusDesc']}
    columnHidingEnabled
    height={500}
    width="100%"
    showBorders
    allowColumnReordering
    onRowExpanded={({ key }) => {
      onSelectStatusGroup(key.statusCode);
    }}
    onRowCollapsed={() => {
      onSelectStatusGroup(null);
    }}
  >
    <Grouping autoExpandAll={false} />

    <LoadPanel enabled />
    <Paging pageSize={PAGE_SIZE} />
    <Pager
      showInfo
      showNavigationButtons
      visible={isPagerVisible(PAGE_SIZE, statusDetailData?.length)}
    />
    <Column
      dataField="statusCode"
      caption="Status Code"
      alignment="left"
      minWidth={170}
      width={180}
      defaultSortOrder="asc"
    />
    {/* <Column
      dataField="locationCity"
      caption="Location"
      minWidth={160}
      width="auto"
    /> */}
    <Column
      dataField="statusDesc"
      caption="Status Description"
      minWidth={110}
      width={180}
    />
    <Column
      dataField="quantity"
      caption="Vehicle Count"
      minWidth={180}
      alignment="left"
    />
    <MasterDetail
      enabled
      render={(row: { data: FormattedStatusData }) => (
        <PipelineDataGridInner statusDetailData={row.data.items} />
      )}
    />
    <Export enabled />
  </DataGrid>
);
