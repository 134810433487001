import React, { useState, useCallback } from 'react';

import { AddDocumentTypeModal } from '@/ui/components/AddDocumentTypeModal/AddDocumentTypeModal';
import {
  CloseAddDocumentTypeModal,
  OpenAddDocumentTypeModal,
  RenderAddDocumentTypeModalType,
  UseAddDocumentTypesModal,
  UseAddDocumentTypeModalState
} from '@/ui/components/AddDocumentTypeModal/types';

const initialState: UseAddDocumentTypeModalState = {
  isOpen: false,
};

export const useAddDocumentTypeModal: UseAddDocumentTypesModal = () => {
  const [{ isOpen }, setState] =
    useState<UseAddDocumentTypeModalState>(initialState);

  const openAddDocumentTypeModal: OpenAddDocumentTypeModal = useCallback(
    () => setState({ isOpen: true }),
    [],
  );

  const closeAddDocumentTypeModal: CloseAddDocumentTypeModal = useCallback(
    () => setState(initialState),
    [],
  );

  const RenderAddDocumentTypeModal: RenderAddDocumentTypeModalType = useCallback(
    () => (
      <AddDocumentTypeModal
        isOpen={isOpen}
        closeModal={closeAddDocumentTypeModal}
      />
    ),
    [isOpen, closeAddDocumentTypeModal],
  );

  return {
    openAddDocumentTypeModal,
    RenderAddDocumentTypeModal,
  };
};
