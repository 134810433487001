import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../queryKeys';
import { pipelines } from '../Pipelines';

type OptionsType = {
  enabled: boolean;
};

export const usePipelineStatus = (locationId?: string, options?: OptionsType) =>
  useQuery(
    [QUERY_KEYS.pipeline, locationId],
    async () => (await pipelines.getStatuses(locationId)).data,
    options,
  );
