import { AppFeatures, AppRoles } from '@/types';

export const hasFeatureAccess = (
  featuresDisabled: AppFeatures[],
  feature?: AppFeatures,
) => {
  if (!feature) {
    return true;
  }

  return !featuresDisabled.includes(feature);
};

export const hasRoleAccess = (
  userRoles: AppRoles[],
  allowedRoles?: AppRoles[],
) => {
  if (!allowedRoles) {
    return true;
  }

  return userRoles.some((role) => allowedRoles.includes(role));
};
