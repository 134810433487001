import { defaultTheme } from '@/ui/theme';

const rowHeight = 20;

const borderStyle = {
  style: 'thin',
  color: { argb: defaultTheme.mainExcelBorderColor },
};

const fillStyle = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: defaultTheme.mainExcelHeaderColor },
};
const bordersStyle = {
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
  top: borderStyle,
};

const alignmentStyle = {
  horizontal: 'center',
};

const fontStyle = {
  bold: true,
};

export const storageExcelConfiguration = {
  columns: {
    serialNumber: 'Serial Number',
    productDesc: 'Product Desc',
    arrivalDate: 'Arrival Date',
    countryDesc: 'Country Desc',
  },
  height: rowHeight,
  fill: fillStyle,
  border: bordersStyle,
  alignment: alignmentStyle,
  font: fontStyle,
};
