import React, { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Col,
  Column,
  DataGrid,
  DevButton,
  FilterRow,
  LoadPanel,
  Pager,
  Paging,
  Popup,
  Sorting,
} from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { useTranslate } from '@/translations';
import { systemAdministrationRoutes } from '@/routes';
import { PopupComponent } from '@/modules/SystemAdministration/Damages/components/Popup';
import { DamagesDataGridProps } from './types';

const PAGE_SIZE = 16;

export const DamagesDataGrid: FC<DamagesDataGridProps> = ({ dataSource }) => {
  const { t } = useTranslate();

  const [isPopupVisible, setPopupVisibility] = useState(false);

  const togglePopup = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
  }, [isPopupVisible]);

  const contentRender = useCallback(
    () => <PopupComponent onTogglePopup={togglePopup} />,
    [togglePopup],
  );

  const onRenderCell = useCallback(
    ({ data }) => {
      const isArchive = data.description === 'STANDARD';

      return (
        <>
          <DevButton
            text={t(`${isArchive ? 'archive' : 'swap'}`)}
            stylingMode="outlined"
            icon="edit"
          />
          <Link
            to={{
              pathname: `${systemAdministrationRoutes.damage}/${data.id}`,
              search: encodeURI(data.description),
            }}
          >
            <DevButton text={t('edit')} stylingMode="outlined" icon="edit" />
          </Link>
        </>
      );
    },
    [t],
  );

  return (
    <Col xxl={8} xl={10} xs={12} className="mt-2">
      <div className="my-3 d-flex gap-2">
        <DevButton text={t('add')} stylingMode="outlined" icon="add" />
        <DevButton
          text={t('import')}
          stylingMode="outlined"
          icon="import"
          onClick={togglePopup}
        />
      </div>

      <Popup
        visible={isPopupVisible}
        onHiding={togglePopup}
        closeOnOutsideClick
        showCloseButton={false}
        showTitle={false}
        position="center"
        maxWidth="720"
        height="auto"
        contentComponent={contentRender}
      />

      <DataGrid
        id="gridContainer"
        keyExpr="id"
        dataSource={dataSource}
        columnHidingEnabled
        width="100%"
        showBorders
        showRowLines
        rowAlternationEnabled
      >
        <FilterRow visible />
        <Sorting mode="multiple" />
        <LoadPanel enabled />
        <Paging pageSize={PAGE_SIZE} />
        <Pager
          showInfo
          showNavigationButtons
          visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
        />
        <Column dataField="description" />
        <Column dataField="inheritedForm" />
        <Column dataField="associatedTemplates" alignment="left" />
        <Column cellRender={onRenderCell} width={220} />
      </DataGrid>
    </Col>
  );
};
