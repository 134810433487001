import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  padding-bottom: 60px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const PageCount = styled.div`
  margin: 0 10px;
`;