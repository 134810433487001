import { PageContainer } from '@/ui/components/Page';

import React, { FC } from 'react';
import { Title } from '@/ui/components/Title';
import { faTools } from '@/ui/components/Icon';
import { useTranslate } from '@/translations';
import { useTheme } from 'styled-components';
import { BackButton } from '@/ui/components/BackButton';
import { systemAdministrationRoutes } from '@/routes';
import { useLocation } from 'react-router-dom';
import { DamageDataGrid } from './components/DamageDataGrid';

const mockData = [
  {
    id: 1,
    componentPartType: 'Accelerator Part Rubber',
    fault: 'Worn/Missing',
    subFault: '',
    size: '',
    count: '',
    action: 'Replace',
    severity: '',
  },
  {
    id: 2,
    componentPartType: 'Accelerator Part Rubber',
    fault: 'Worn/Missing',
    subFault: '',
    size: '',
    count: '',
    action: 'Report Only',
    severity: '',
  },
  {
    id: 3,
    componentPartType: 'Ad Blue Fluid',
    fault: 'Low/Empty',
    subFault: '',
    size: '',
    count: '',
    action: 'Refill',
    severity: '',
  },
  {
    id: 4,
    componentPartType: 'Ad Blue Fluid',
    fault: 'Low/Empty',
    subFault: '',
    size: '',
    count: '',
    action: 'Report Only',
    severity: '',
  },
  {
    id: 5,
    componentPartType: 'Ad Blue Fluid',
    fault: 'Leak',
    subFault: '',
    size: '',
    count: '',
    action: 'Estimate Required',
    severity: '',
  },
  {
    id: 6,
    componentPartType: 'Ad Blue Fluid',
    fault: 'Leak',
    subFault: '',
    size: '',
    count: '',
    action: 'Report Only',
    severity: '',
  },
  {
    id: 7,
    componentPartType: 'Ad Blue Fluid',
    fault: 'Damage',
    subFault: '',
    size: '',
    count: '',
    action: 'Repair',
    severity: '',
  },
  {
    id: 8,
    componentPartType: 'Ad Blue Fluid',
    fault: 'Damage',
    subFault: '',
    size: '',
    count: '',
    action: 'Repair',
    severity: '',
  },
  {
    id: 9,
    componentPartType: 'Ad Blue Fluid',
    fault: 'Damage',
    subFault: '',
    size: '',
    count: '',
    action: 'Report Only',
    severity: '',
  },
  {
    id: 10,
    componentPartType: 'Ad Blue Fluid',
    fault: 'Missing',
    subFault: '',
    size: '',
    count: '',
    action: 'Replace',
    severity: '',
  },
  {
    id: 11,
    componentPartType: 'Ad Blue Fluid',
    fault: 'Missing',
    subFault: '',
    size: '',
    count: '',
    action: 'Report Only',
    severity: '',
  },
];

export const Damage: FC = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();
  const { search } = useLocation();

  const description = decodeURI(search).substring(1);

  return (
    <PageContainer fluid>
      <Title title={t('repairActionMatrix')} icon={faTools} color={accent} />
      <BackButton linkTo={`${systemAdministrationRoutes.damages}`} />

      <h3 className="mt-4 text-muted fw-bold">{description}</h3>

      <DamageDataGrid dataSource={mockData} />
    </PageContainer>
  );
};
