import React, { FC } from 'react';
import { NoPreviewWrapper, NoPreviewText, NoPreviewButton } from '@/ui/components/PreviewModal/PreviewModal.styled';
import { useTranslate } from '@/translations';

interface NoPreviewProps {
  closeModal: () => void
}

export const NoPreview: FC<NoPreviewProps> = ({ closeModal }) => {
  const { t } = useTranslate();

  return (
    <NoPreviewWrapper>
      <NoPreviewText>{t('unableToPreviewDocType')}</NoPreviewText>
      <NoPreviewText>{t('pleaseDownloadToView')}</NoPreviewText>
      <NoPreviewButton onClick={closeModal}>{t('ok')}</NoPreviewButton>
    </NoPreviewWrapper>
  );
}
