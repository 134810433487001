import styled from 'styled-components';
import { Container } from '@/ui';

export const PassportContainer = styled(Container)`
  padding-top: 24px;
  padding-bottom: 24px;
  text-align: center;
`;

export const InputStyling = styled(Container)`
  max-width: 30%;
`;

export const PassportBorder = styled(Container)`
  background-color: #001957;
  border: #001957 solid 3px;
  border-radius: 1.75rem;
  background-size: 100% auto;
  margin-top: 1%;
`;

export const PassportLeft = styled(Container)`
  border-radius: 1.3rem 0 0 1.3rem;
  box-shadow: inset 3px 0 20px rgb(0 0 0 / 20%), 0 0 15px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  background-color: #fff;
  border-top: rgb(0 0 0 / 20%) solid 3px;
  border-bottom: rgb(0 0 0 / 20%) solid 3px;
  height: 100%;
  width: 104%;
  text-align: initial;
`;
export const PassportRight = styled(Container)`
  border-radius: 0 1.3rem 1.3rem 0;
  box-shadow: inset 3px 0 20px rgb(0 0 0 / 20%), 0 0 15px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  background-color: #fff;
  border-top: rgb(0 0 0 / 20%) solid 3px;
  border-bottom: rgb(0 0 0 / 20%) solid 3px;
  height: 100%;
`;
