import React, { FC, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { Title } from '@/ui/components/Title';
import { faFile } from '@/ui/components/Icon';
import { Loader } from '@/ui/components/Loader';
import { PageContainer } from '@/ui/components/Page';
import { useOrderSummary } from '@/api/Order';
import { useDeleteBillOfLading } from '@/api/BillOfLading';
import { OrderDataGrid } from './components/OrderDataGrid';
import { formatOrderSummary } from './utils';

export const AdminOrder: FC = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();
  const { data, isLoading } = useOrderSummary();
  const { mutate: deleteMutate } = useDeleteBillOfLading();

  const formattedOrderSummary = useMemo(
    () => formatOrderSummary(data?.entities || []),
    [data?.entities],
  );

  const handleDeleteBillOfLading = useCallback(
    (movementId: number) => {
      deleteMutate(movementId);
    },
    [deleteMutate],
  );

  if (isLoading) return <Loader />;

  return (
    <PageContainer fluid>
      <Title title={t('orderSummaryAdmin')} icon={faFile} color={accent} />
      <OrderDataGrid
        dataSource={formattedOrderSummary}
        deleteBillOfLading={handleDeleteBillOfLading}
      />
    </PageContainer>
  );
};
