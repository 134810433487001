import {
  localDateFormatted,
  localDateTimeFormatted,
  localTimeFormatted,
} from '@/utils/helpers';
import { getValueOrNA } from '@/ui/components/NotApplicable';

import { LicensePlateComponent } from '../LicensePlateComponent';
import { FieldRenderers } from './types';

export const fieldRenderers: FieldRenderers = {
  regNumber: (data, t) => data.regNumber
    ? LicensePlateComponent(data.regNumber, { zoom: 1.5 })
    : getValueOrNA('regNumber')({ data: { regNumber: t('noRegNumber') } }),
  inspectionDate: (data) => data.inspectionDateTime ? localDateFormatted(data.inspectionDateTime) : getValueOrNA('inspectionDate')({ data }),
  inspectionTime: (data) => data.inspectionDateTime ? localTimeFormatted(data.inspectionDateTime) : getValueOrNA('inspectionDate')({ data }),
  approvalDateTime: (data) => data.approvalDateTime ? localDateTimeFormatted(data.approvalDateTime) : getValueOrNA('approvalDateTime')({ data }),
};
