import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import { PageContainer } from '@/ui/components/Page';
import { Title } from '@/ui/components/Title';
import { useTranslate } from '@/translations';
import { faFileLines } from '@/ui/components/Icon';
import { useDocuments } from '@/api/DocumentManagement';
import { Loader } from '@/ui/components/Loader';
import { DocumentManagementDataGrid } from '@/modules/DocumentManagement/components';
import { Document } from '@/types';

export const DocumentManagement: FC = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();
  const { isLoading, data } = useDocuments();

  if (isLoading) return <Loader />;

  return (
    <PageContainer fluid>
      <Title
        title={t('documentManagement')}
        icon={faFileLines}
        color={accent}
      />
      <DocumentManagementDataGrid documents={data?.entities as Document[]} />
    </PageContainer>
  );
};
