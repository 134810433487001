import { useMutation } from 'react-query';
import { queryClient } from '@/config/reactQuery';
import { QUERY_KEYS } from '../../queryKeys';
import { billOfLading } from '../BillOfLading';

export const useUploadBillOfLading = () =>
  useMutation(billOfLading.uploadBillOfLading);

export const useDeleteBillOfLading = () =>
  useMutation(billOfLading.deleteBillOfLading, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.orderSummary);
    },
  });
