import { Workbook, exportDataGrid, saveAs, ExportingEvent } from '@/ui';
import { Inspection } from '@/types';
/* eslint-disable no-param-reassign */
export const handleExport = (e: ExportingEvent<Inspection>) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');

  exportDataGrid({
    component: e.component,
    worksheet,
    topLeftCell: { row: 1, column: 1 },
    customizeCell: ({ gridCell, excelCell }) => {
      if (
        gridCell?.rowType === 'data' &&
        (gridCell?.column?.dataType === 'date' ||
          gridCell?.column?.dataType === 'datetime')
      ) {
        excelCell.numFmt = 'dd/MM/yyyy hh:mm UTC';
      }
    },
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        'Inspections.xlsx',
      );
    });
  });
  e.cancel = true;
};
