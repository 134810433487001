import React, { useEffect, useState } from 'react';
import { Title } from '@/ui/components/Title';
import { useTranslate } from '@/translations';
import { faPassport } from '@/ui/components/Icon';
import { useTheme } from 'styled-components';
import { usePassports, usePassportsTransactions } from '@/api/Passport';
import { useSearchParams } from 'react-router-dom';
import { Col, Container, Row, TextBox } from '@/ui';
import { useDebounce } from '@/hooks';

import { PassportDetails } from './components/PassportDetails';
import { PassportTransactions } from './components/PassportTransactions';
import {
  PassportContainer,
  InputStyling,
  PassportBorder,
  PassportLeft,
  PassportRight,
} from './Passport.styled';
import { InspectionsDataGrid } from './components/InspectionsDataGrid';

export const Passport = () => {
  const { t } = useTranslate();
  const { passport } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('search') || '',
  );

  const debouncedSearchValue = useDebounce(searchValue, 400);

  const { data: passportsData, isLoading: isPassportsLoading } = usePassports(
    { search: debouncedSearchValue },
    { enabled: !!debouncedSearchValue.length },
  );

  const {
    data: passportsTransactionsData,
    isLoading: isPassportsTransactionLoading,
  } = usePassportsTransactions(
    { search: debouncedSearchValue },
    { enabled: !!debouncedSearchValue.length },
  );

  const passportData = passportsData?.entities[0];

  useEffect(() => {
    if (isPassportsLoading) return;

    if (passportData) {
      setSearchParams({ search: debouncedSearchValue })
    } else if (searchParams.has('search')) {
      setSearchParams({});
    }
  }, [debouncedSearchValue, passportData, isPassportsLoading, searchParams, setSearchParams]);

  return (
    <PassportContainer fluid>
      <Title title={t('passport')} icon={faPassport} color={passport} />
      <InputStyling>
        <TextBox
          placeholder={t('search')}
          value={searchValue}
          valueChangeEvent="input"
          maxLength={17}
          onValueChange={setSearchValue}
        />
      </InputStyling>

      {!!debouncedSearchValue.length && (
        <PassportBorder>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <PassportLeft>
                <Container>
                  <PassportDetails
                    passportData={passportData}
                    isLoading={isPassportsLoading}
                  />
                </Container>
              </PassportLeft>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <PassportRight>
                <Container>
                  <Row className="mt-2">
                    <PassportTransactions
                      passportsTransactionsData={passportsTransactionsData}
                      isLoading={isPassportsTransactionLoading}
                    />
                  </Row>
                  <Row className="mt-2">
                    <InspectionsDataGrid
                      passportsData={passportsData}
                      isLoading={isPassportsLoading}
                    />
                  </Row>
                </Container>
              </PassportRight>
            </Col>
          </Row>
        </PassportBorder>
      )}
    </PassportContainer>
  );
};
