import React, { FC } from 'react';

import { PageContainer } from '@/ui/components/Page';
import { useInspectionsVehicle } from '@/api/Inspections';
import { Loader } from '@/ui/components/Loader';
import { useParams } from 'react-router-dom';
import { Inspection, TabDocument } from '@/types';
import { InspectionStatusMap } from '@/utils/constants';

import { DocumentsDataGrid } from './components/DocumentsDataGrid';
import { InspectionPageTab } from '../types';

const getInitialData = () => [
  {
    id: 0,
    description: 'Mechanical Report initial',
    fileUrl: (id: string) => `/report/${id}/mechanical`,
    fileName: 'Mechanical Report initial',
    externalLink: (vin: string) =>
      `https://bmwreports.azurewebsites.net/mechanicalReport/${vin}`,
  },
  {
    id: 1,
    description: 'Vehicle Appraisal Report initial',
    fileUrl: (id: string) => `/report/${id}/vehicle`,
    fileName: 'Vehicle Appraisal Report initial',
    externalLink: (vin: string) =>
      `https://bmwtmsintegration.azurewebsites.net/link/${vin}`,
  },
];

const getApprovedData = () => [
  {
    id: 2,
    description: 'Mechanical Report approved',
    fileUrl: (id: string) => `/report/${id}/mechanical/approved`,
    fileName: 'Mechanical Report approved',
    externalLink: (vin: string) =>
      `https://bmwreports.azurewebsites.net/mechanicalReport/${vin}`,
  },
  {
    id: 3,
    description: 'Vehicle Appraisal Report approved',
    fileUrl: (id: string) => `/report/${id}/vehicle/approved`,
    fileName: 'Vehicle Appraisal Report approved',
    externalLink: (vin: string) =>
      `https://bmwtmsintegration.azurewebsites.net/link/${vin}`,
  },
];

const getDocumentsList = (data: Inspection): TabDocument[] => {
  if ([InspectionStatusMap.Authorised, InspectionStatusMap.Completed].includes(data?.inspectionStatus)) {
    return [...getInitialData(), ...getApprovedData()];
  }

  return getInitialData();
};

export const Documents: FC<InspectionPageTab> = () => {
  const { id } = useParams();
  const { isLoading, data } = useInspectionsVehicle(Number(id));

  const inspection = data?.entities?.[0];

  if (isLoading || !inspection) return <Loader />;

  return (
    <PageContainer fluid>
      <DocumentsDataGrid
        dataSource={getDocumentsList(inspection)}
        vin={inspection?.vin}
      />
    </PageContainer>
  );
};
