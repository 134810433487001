import React, { FC, useState, useRef, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useMsal } from '@azure/msal-react';
import { useUpdateTenantBranding } from '@/api/TenantCustomization';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { useTranslate } from '@/translations';
import { PageContainer } from '@/ui/components/Page';
import { faHomeUser } from '@/ui/components/Icon';
import { Title } from '@/ui/components/Title';
import { Button } from '@/ui/components/Button';
import { Loader } from '@/ui/components/Loader';
import { Form, Row, Col } from '@/ui';
import { b2cPolicies, deployment } from '@/auth/config';
import { TenantIconPreview } from './TenantCustomization.styled';

const fileReader = new FileReader();

export const TenantCustomization: FC = () => {
  const { instance, accounts } = useMsal();
  const idTokenClaims = useIdTokenClaims();
  const { t } = useTranslate();
  const { defaultLink, accent, main } = useTheme();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [color1, setColor1] = useState(accent);
  const [color2, setColor2] = useState(main);
  const [tenantIcon, setTenantIcon] = useState<string | null>(null);
  const { mutateAsync, isLoading } = useUpdateTenantBranding();

  const handleFileLoad = () => {
    const { result } = fileReader;
    if (typeof result === 'string') {
      setTenantIcon(fileReader.result as string);
    }
  };

  useEffect(() => {
    fileReader.addEventListener('load', handleFileLoad, false);

    return () => {
      fileReader.removeEventListener('load', handleFileLoad);
    };
  }, []);

  const handleFormSave = () => {
    mutateAsync({
      color1: {
        isChanged: color1 !== accent,
        value: color1,
      },
      color2: {
        isChanged: color2 !== main,
        value: color2,
      },
      iconBase64: {
        isChanged: !!tenantIcon,
        value: tenantIcon,
      },
    }).then(() => {
      instance.loginRedirect({
        authority: b2cPolicies.authorities.signIn.authority,
        scopes: [
          'openid',
          'profile',
          `https://${deployment.b2cTenantName}.onmicrosoft.com/mtrest/User.Invite`,
          `https://${deployment.b2cTenantName}.onmicrosoft.com/mtrest/User.ReadAll`,
        ],
        account: accounts[0],
        extraQueryParameters: {
          tenant: idTokenClaims?.appTenantId as string,
        },
      });
    });
  };

  const handleFileChange = () => {
    const selectedFile = fileInputRef.current?.files?.[0];
    if (selectedFile) {
      fileReader.readAsDataURL(selectedFile);
    } else {
      setTenantIcon(null);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageContainer fluid>
      <Title
        title={t('tenantCustomization')}
        icon={faHomeUser}
        color={defaultLink}
      />
      <Row>
        <Col lg={6}>
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>
                {t('uploadIcon')}
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="image/*"
                />
                {tenantIcon && (
                  <TenantIconPreview src={tenantIcon} alt="New tenant icon" />
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>
                {t('selectStyleColor1')}
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="color"
                  value={color1}
                  onChange={(e) => setColor1(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={4}>
                {t('selectStyleColor2')}
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="color"
                  value={color2}
                  onChange={(e) => setColor2(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col sm={4} />
              <Col sm={8}>
                <Button title={t('saveChanges')} onClick={handleFormSave} />
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </PageContainer>
  );
};
