import styled from 'styled-components';
import { Container } from '@/ui';

export const PageContent = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const PageContainer = styled(Container)`
  padding-top: 24px;
  padding-bottom: 24px;
`;
