import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid, Paging, Pager, Column } from '@/ui';
import { isPagerVisible } from '@/utils/helpers';
import { FORMAT_DATE_WITH_TIME } from '@/utils/constants';
import { getDetailGridDataSource } from './utils';
import { StorageGridDetailSectionProps } from './types';

const PAGE_SIZE = 6;

function renderGridCell(data: string) {
  return (
    <Link
      to={{
        pathname: '/passport',
        search: `search=${data}`,
      }}
    >
      View
    </Link>
  );
}

export const StorageGridDetailSection: FC<StorageGridDetailSectionProps> = ({
  data,
  storageData,
}) => (
  <DataGrid
    dataSource={getDetailGridDataSource(data, storageData)}
    columnHidingEnabled
    showRowLines
    width="100%"
    showBorders
    allowColumnReordering
  >
    <Paging pageSize={PAGE_SIZE} />
    <Pager
      showInfo
      showNavigationButtons
      visible={isPagerVisible(PAGE_SIZE, storageData?.length)}
    />
    <Column
      caption="Passport History"
      alignment="center"
      cellRender={(e) => renderGridCell(e.data.serialNumber)}
    />
    <Column dataField="serialNumber" minWidth={170} alignment="left" />
    <Column
      dataField="productDesc"
      minWidth={180}
      alignment="left"
      hidingPriority={1}
    />
    <Column
      dataField="arrivalDate"
      minWidth={180}
      alignment="left"
      hidingPriority={2}
      dataType="datetime"
      format={FORMAT_DATE_WITH_TIME}
    />
    <Column
      dataField="countryDesc"
      minWidth={180}
      alignment="left"
      hidingPriority={3}
    />
  </DataGrid>
);
