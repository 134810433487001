import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Popup, Form, Row, Col, notify } from '@/ui';
import { useTranslate } from '@/translations';
import { useUploadDocument, useDocumentTypes } from '@/api/DocumentManagement';
import { Loader } from '@/ui/components/Loader';
import { Button } from '@/ui/components/Button';
import { DocumentType, UploadDocument, AppEntities } from '@/types';

import { useSearchParams } from 'react-router-dom';

import { Title } from './UploadDocumentModal.styled';
import { UploadDocumentModalProps } from './types';

const fileReader = new FileReader();

export const UploadDocumentModal: FC<UploadDocumentModalProps> = memo(
  ({ isOpen, closeModal, initialValues }) => {
    const { isLoading, data } = useDocumentTypes();
    const { mutateAsync: uploadDocument } = useUploadDocument();
    const { t } = useTranslate();
    const [, setSearchParams] = useSearchParams();

    const [fileName, setFileName] = useState<string>('');
    const [file, setFile] = useState<string>('');
    const [documentTypeCode, setDocumentTypeCode] = useState<string>('');
    const [entity, setEntity] = useState<AppEntities>(
      initialValues?.entity || AppEntities.Vessel,
    );
    const [entityReference, setEntityReference] = useState<string>(
      initialValues?.entityReference || '',
    );

    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (!isLoading && !documentTypeCode) {
        setDocumentTypeCode(data?.entities[0]?.documentTypeCode || '');
      }
    }, [isLoading, data, documentTypeCode]);

    const handleFileLoad = () => {
      const { result } = fileReader;
      if (typeof result === 'string') {
        setFile(fileReader.result as string);
      }
    };

    useEffect(() => {
      fileReader.addEventListener('load', handleFileLoad, false);

      return () => {
        fileReader.removeEventListener('load', handleFileLoad);
      };
    }, []);

    const handleFileChange = () => {
      const selectedFile = fileInputRef.current?.files?.[0];
      if (selectedFile) {
        setFileName(selectedFile.name || 'document');
        fileReader.readAsDataURL(selectedFile);
      } else {
        setFileName('');
        setFile('');
      }
    };

    const handleSubmit = async () => {
      const requiredFields = {
        fileName,
        documentFileBase64: file,
        documentTypeCode,
        entity,
        entityReference,
      };

      if (Object.values(requiredFields).some((value) => !value)) {
        notify(
          {
            message: 'All fields are required',
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          'error',
          10000,
        );

        return;
      }

      const body: UploadDocument = { ...requiredFields };

      try {
        await uploadDocument(body);
        closeModal();
        // clear all URL Search Params
        setSearchParams({}, { replace: true });
      } catch (error) {
        notify(
          {
            // @ts-ignore
            message: error.response.data.Message,
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          'error',
          10000,
        );
      }
    };

    const handleCloseModal = useCallback(() => {
      closeModal();
      // clear all URL Search Params
      setSearchParams({}, { replace: true });
    }, [closeModal, setSearchParams]);

    return (
      <Popup visible={isOpen} onHiding={handleCloseModal}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Title>{t('uploadDocument')}</Title>
            <Row>
              <Col lg={6}>
                <Form>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      {t('selectFile')}
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept="image/*"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      {t('documentTypeCode')}
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Select
                        value={documentTypeCode}
                        onChange={(e) => setDocumentTypeCode(e.target.value)}
                      >
                        {data?.entities.map((item: DocumentType) => (
                          <option
                            key={item.documentTypeCode}
                            value={item.documentTypeCode}
                          >
                            {item.documentTypeCode}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      {t('entity')}
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Select
                        value={entity}
                        onChange={(e) =>
                          setEntity(e.target.value as AppEntities)
                        }
                      >
                        {Object.keys(AppEntities).map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}>
                      {t('entityReference')}
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        value={entityReference}
                        onChange={(e) => setEntityReference(e.target.value)}
                        placeholder="Vessel name / Transport number / Vehicle VIN"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Col sm={4} />
                    <Col sm={8}>
                      <Button title={t('upload')} onClick={handleSubmit} />
                    </Col>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Popup>
    );
  },
);
