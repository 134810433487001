import React, { FC, useCallback } from 'react';
import { Column, DataGrid, LoadPanel, Pager, Paging } from '@/ui';
import { isPagerVisible, localDateFormatted } from '@/utils/helpers';
import { Button } from '@/ui/components/Button';
import { useTranslate } from '@/translations';
import { PointInTimeReportsDataGridProps } from './types';

const PAGE_SIZE = 6;

export const PointInTimeReportsDataGrid: FC<
  PointInTimeReportsDataGridProps
> = ({ dataSource }) => {
  const { t } = useTranslate();

  const onRenderCell = useCallback(
    ({ data }) => (
      <a href={data.image} download target="_blank" rel="noreferrer" aria-label="View">
        <Button size="sm" title={t('viewReport')} />
      </a>
    ),
    [t],
  );

  return (
    <DataGrid
      id="gridContainer"
      dataSource={dataSource}
      keyExpr="id"
      width="100%"
      showBorders
      showRowLines
    >
      <LoadPanel enabled />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />

      <Column dataField="date" format={localDateFormatted} />
      <Column dataField="event" />
      <Column width={220} cellRender={onRenderCell} />
    </DataGrid>
  );
};
