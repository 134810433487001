import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Button, Popup, Form, Row, Col } from '@/ui';
import { useTranslate } from '@/translations';
import { NumberBoxWrapper } from '@/modules/Inspection/components/tabs/Damage/components/CreateFlatRateCodePopup/CreateFlatRateCodePopup.styled';

export const AddFleetPopup: FC<{
  isPopupVisible: boolean;
  onTogglePopup: () => void;
}> = ({ isPopupVisible, onTogglePopup }) => {
  const { t } = useTranslate();

  const [formData, setFormData] = useState({
    code: '',
    name: '',
    contractName: '',
    address: '',
    postCode: '',
    country: '',
    telephone: '',
    emailAddress: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleTogglePopup = useCallback(() => {
    onTogglePopup();
  }, [onTogglePopup]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const {
      code,
      name,
      contractName,
      address,
      postCode,
      country,
      telephone,
      emailAddress,
    } = formData;

    const body = {
      code,
      name,
      contractName,
      address,
      postCode,
      country,
      telephone,
      emailAddress,
    };

    console.log('body:', body);

    handleTogglePopup();
  };

  return (
    <Popup
      visible={isPopupVisible}
      onHiding={onTogglePopup}
      title={t('addFleet')}
      position="center"
      minWidth="320"
      maxWidth="600"
      height="auto"
    >
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="code">
          <Form.Label column sm="4">
            {t('code')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              name="code"
              value={formData.code}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="name">
          <Form.Label column sm="4">
            {t('name')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="contractName">
          <Form.Label column sm="4">
            {t('contractName')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              name="contractName"
              value={formData.contractName}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="address">
          <Form.Label column sm="4">
            {t('address')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="postCode">
          <Form.Label column sm="4">
            {t('postCode')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              name="postCode"
              value={formData.postCode}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="country">
          <Form.Label column sm="4">
            {t('country')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              name="country"
              value={formData.country}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="telephone">
          <Form.Label column sm="4">
            {t('telephone')}
          </Form.Label>
          <Col sm="8">
            <NumberBoxWrapper>
              <Form.Control
                name="telephone"
                value={formData.telephone}
                onChange={handleInputChange}
              />
            </NumberBoxWrapper>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="emailAddress">
          <Form.Label column sm="4">
            {t('emailAddress')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>

        <div className="mt-3 d-flex gap-2 justify-content-end">
          <Button type="submit" disabled>
            {t('save')}
          </Button>
          <Button type="button" onClick={handleTogglePopup}>
            {t('cancel')}
          </Button>
        </div>
      </Form>
    </Popup>
  );
};
