import React from 'react';
import { Link } from 'react-router-dom';
import { Column, DataGrid, FilterRow, Pager } from '@/ui';
import { VesselMovement } from '@/types';

type VesselDataGridInnerProps = {
  dataSource: VesselMovement[];
};

function renderGridCell(data: string) {
  return (
    <Link
      to={{
        pathname: '/passport',
        search: `search=${data}`,
      }}
    >
      View
    </Link>
  );
}

export const VesselDataGridInner = ({
  dataSource,
}: VesselDataGridInnerProps) => (
  <DataGrid
    dataSource={dataSource}
    columnHidingEnabled
    showRowLines
    width="100%"
    showBorders
    allowColumnReordering
    paging={{
      pageSize: 6,
    }}
  >
    <FilterRow visible />
    <Pager showInfo showNavigationButtons visible />
    <Column
      caption="Passport History"
      alignment="center"
      cellRender={(e) => renderGridCell(e.data.serialNumber)}
    />
    <Column dataField="serialNumber" minWidth={170} alignment="left" />
    <Column dataField="productDesc" minWidth={170} alignment="left" />
    <Column
      dataField="despatchDate"
      minWidth={20}
      width={140}
      alignment="left"
      dataType="date"
      format="dd/MM/yyyy"
    />
    <Column
      caption="ETA Date"
      dataField="etaDate"
      minWidth={20}
      width={120}
      alignment="left"
      dataType="date"
      format="dd/MM/yyyy"
    />
  </DataGrid>
);
