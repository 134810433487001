import { AxiosPromise } from 'axios';
import { Core, BILL_OF_LADING } from '../Core';

interface UploadBillOfLadingData {
  movementId: number;
  data: {
    billOfLadingFileBase64: ArrayBuffer | string | null;
    fileName?: string;
  };
}

export type UploadBillOfLading = (
  data: UploadBillOfLadingData,
) => AxiosPromise<boolean>;

type DeleteBillOfLading = (movementId: number) => AxiosPromise<boolean>;

class BillOfLading extends Core {
  uploadBillOfLading: UploadBillOfLading = async ({
    movementId,
    data,
  }: UploadBillOfLadingData) =>
    this.post<typeof data, AxiosPromise<boolean>>(
      BILL_OF_LADING(movementId),
      data,
    );

  deleteBillOfLading: DeleteBillOfLading = async (movementId) =>
    this.delete<AxiosPromise>(BILL_OF_LADING(movementId));
}

export const billOfLading = new BillOfLading();
