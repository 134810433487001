import React from 'react';
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleMinus,
  Icon,
} from '@/ui/components/Icon';

const iconMap = {
  Pass: { icon: faCircleCheck, className: 'text-success' },
  Ok: { icon: faCircleCheck, className: 'text-success' },
  Yes: { icon: faCircleCheck, className: 'text-success' },
  Fail: { icon: faCircleExclamation, className: 'text-danger' },
  FailToReport: { icon: faCircleExclamation, className: 'text-danger' },
  'Requires attention': { icon: faCircleExclamation, className: 'text-danger' },
  'Not Applicable': { icon: faCircleMinus, className: '' },
  Default: { icon: faCircleMinus, className: '' },
};

const RenderIcons = ({ value }: { value: string }) => {
  const { icon, className } =
    iconMap[value as keyof typeof iconMap] || iconMap.Default;

  return (
    <span className={className}>
      <Icon icon={icon} />
    </span>
  );
};

export default RenderIcons;
