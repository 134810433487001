import { AxiosPromise } from 'axios';
import { CreateTenant, TenantsData, UpdateTenant } from '@/types';
import { Core, USERS, GBA_USERS, TENANTS } from '../Core';

type GetTenants = () => AxiosPromise<TenantsData>;
type GetUserTenants = (userId?: number) => AxiosPromise<TenantsData>;
type GetGBATenants = (gbaUserId: number) => AxiosPromise<TenantsData>;
type CreateTenants = (data: CreateTenant) => AxiosPromise<boolean>;
type UpdateTenants = (data: UpdateTenant) => AxiosPromise<boolean>;

class Tenants extends Core {
  getTenants: GetTenants = async () => this.get(TENANTS());

  getUserTenants: GetUserTenants = async (userId) =>
    this.get(`${USERS(userId)}/tenants`);

  getGBATenants: GetGBATenants = async (gbaUserId) =>
    this.get(`${GBA_USERS(gbaUserId)}/tenants`);

  createTenants: CreateTenants = async (data) =>
    this.post<CreateTenant, AxiosPromise<boolean>>(TENANTS(), data);

  updateTenants: UpdateTenants = async (data) =>
    this.patch<UpdateTenant, AxiosPromise<boolean>>(TENANTS(), data);
}

export const tenants = new Tenants();
