import { useTranslate } from '@/translations';
import { Card, Col } from '@/ui';
import { TranslationKey } from '@/translations/types';
import React from 'react';
import { TireGroup } from './types';

export const TireCard = ({ tireData }: { tireData: TireGroup }) => {
  const { t } = useTranslate();

  return (
    <Col className="mb-3">
      <Card>
        <Card.Body>
          {Object.entries(tireData).map(([key, value]) => {
            if (key === 'imageFileUrl') return null;

            return (
              <Card.Text
                as="div"
                key={key}
                className="dx-field m-0 d-flex align-items-center"
              >
                <div className="dx-field-label fw-bold">
                  {t(key as TranslationKey)}:
                </div>
                <div className="dx-field-value text-muted">
                  {value || t('notApplicable')}
                </div>
              </Card.Text>
            );
          })}

          {tireData?.imageFileUrl ? (
            <Card.Img
              className="border w-auto mw-100 m-1"
              src={tireData.imageFileUrl}
              loading="lazy"
            />
          ) : (
            t('notApplicable')
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};
