import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, DevButton, Form, Popup, Row, SelectBox, TagBox } from '@/ui';
import { Title } from '@/ui/components/UploadDocumentModal/UploadDocumentModal.styled';
import { useTranslate } from '@/translations';
import { TMSUser } from '@/types/Users/TMSUsers';
import { UpdateTMSUserPopupProps } from './types';

export const UpdateTMSUserPopup: FC<UpdateTMSUserPopupProps> = ({
  inspectors,
  tenants,
  onSelectTenantId,
  sitesMap,
  editUserData,
  onEditTMSUser,
  isEditPopupVisible,
  onToggleEditPopup,
}) => {
  const { t } = useTranslate();

  const [tmsData, setTmsData] = useState<TMSUser>(editUserData);
  const [selectedSiteIds, setSelectedSiteIds] = useState<number[]>([]);

  useEffect(() => {
    if (editUserData) {
      setTmsData({ ...editUserData });
      setSelectedSiteIds(editUserData.siteIds);
    }
  }, [editUserData, sitesMap]);

  const renderTitle = () => (
    <span className="mt-3 d-inline-block">
      <Title>{t('editTMSUser')}</Title>
    </span>
  );

  const inspectorsDataSource = useMemo(
    () =>
      inspectors?.map(({ id, inspectorSurname, inspectorForenames }) => ({
        id,
        displayExpr: `${inspectorForenames} ${inspectorSurname}`,
      })),
    [inspectors],
  );

  const sitesDataSource =
    sitesMap?.[tmsData?.tenantId] &&
    Object.values(sitesMap?.[tmsData?.tenantId]);

  const handleSubmit = useCallback(() => {
    onEditTMSUser({
      ...tmsData,
      siteIds: selectedSiteIds,
    });

    onToggleEditPopup();
  }, [onEditTMSUser, onToggleEditPopup, selectedSiteIds, tmsData]);

  return (
    <Popup
      visible={isEditPopupVisible}
      onHiding={onToggleEditPopup}
      closeOnOutsideClick
      titleRender={renderTitle}
      position="center"
      minWidth="320"
      maxWidth="520"
      height="auto"
    >
      <Form>
        <Form.Group
          as={Row}
          className="mb-3 dx-field m-0 d-flex align-items-center"
          controlId="tenant"
        >
          <Form.Label column sm="4" className="dx-field-label">
            {t('tenant')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              className="dx-field-value d-flex justify-content-end w-100 p-0"
              value={tmsData?.tenantId}
              as={SelectBox}
              items={tenants}
              valueExpr="id"
              displayExpr="name"
              onValueChange={(e: string) => {
                onSelectTenantId(e);
                setTmsData({
                  ...tmsData,
                  tenantId: Number(e),
                  siteIds: Object.keys(
                    sitesMap?.[e as unknown as number] as unknown as number[],
                  ) as unknown as number[],
                });
                setSelectedSiteIds([]);
              }}
              placeholder={t('chooseTenant')}
            />
          </Col>
        </Form.Group>

        <Form.Group
          as={Row}
          className="mb-3 dx-field m-0 d-flex align-items-center"
          controlId="displayName"
        >
          <Form.Label column sm="4" className="dx-field-label">
            {t('displayName')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              className="dx-field-value d-flex justify-content-end w-100"
              value={tmsData?.displayName}
              disabled
            />
          </Col>
        </Form.Group>

        <Form.Group
          as={Row}
          className="mb-3 dx-field m-0 d-flex align-items-center"
          controlId="username"
        >
          <Form.Label column sm="4" className="dx-field-label">
            {t('username')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              className="dx-field-value d-flex justify-content-end w-100"
              value={tmsData?.userName}
              disabled
            />
          </Col>
        </Form.Group>

        <Form.Group
          as={Row}
          className="mb-3 dx-field m-0 d-flex align-items-center"
          controlId="inspector"
        >
          <Form.Label column sm="4" className="dx-field-label">
            {t('inspector')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              className="dx-field-value d-flex justify-content-end w-100 p-0"
              as={SelectBox}
              value={tmsData?.inspectorId}
              items={inspectorsDataSource}
              valueExpr="id"
              displayExpr="displayExpr"
              onValueChange={(e: string) => {
                setTmsData({ ...tmsData, inspectorId: Number(e) });
              }}
              placeholder={t('chooseInspector')}
              showClearButton
            />
          </Col>
        </Form.Group>

        <Form.Group
          as={Row}
          className="mb-3 dx-field m-0 d-flex align-items-center"
          controlId="siteIds"
        >
          <Form.Label column sm="4" className="dx-field-label">
            {t('site')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              className="dx-field-value d-flex justify-content-end w-100 p-0"
              as={TagBox}
              value={selectedSiteIds as unknown as string[]}
              dataSource={sitesDataSource}
              valueExpr="siteId"
              displayExpr="siteName"
              onValueChange={(e: []) => {
                setSelectedSiteIds(e);
              }}
              placeholder={t('chooseSites')}
            />
          </Col>
        </Form.Group>

        <div className="mt-3 d-flex gap-2 justify-content-end px-2">
          <DevButton
            text={t('save')}
            stylingMode="outlined"
            onClick={handleSubmit}
            disabled={!tmsData.displayName || !tmsData.tenantId}
          />

          <DevButton
            text={t('cancel')}
            stylingMode="outlined"
            onClick={onToggleEditPopup}
          />
        </div>
      </Form>
    </Popup>
  );
};
