import React, { FC, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { Icons } from '@/ui/components/Icon';
import { Title } from '@/ui/components/Title';
import { useTransportMovements } from '@/api/TransportMovements';
import { Loader } from '@/ui/components/Loader';
import { TransportMovementFilters } from './components/TransportMovementFilters';
import { TransportDataGrid } from './components/TransportMovementDataGrid';
import { TransportMovementContainer } from './TransportMovement.styled';
import {
  filterTransportMovements,
  formatTransportMovements,
  getTransportMovementsFilters,
} from './utils';
import { TransportMovementFiltersValues } from './types';

export const TransportMovement: FC = () => {
  const { t } = useTranslate();
  const { transportMovementLink } = useTheme();

  const [filterValues, setFilterValues] =
    useState<TransportMovementFiltersValues>({});

  const { data, isLoading } = useTransportMovements();

  const formattedTransportMovements = useMemo(
    () => formatTransportMovements(data?.entities || []),
    [data?.entities],
  );

  const transportMovementsFiltersData = useMemo(
    () => getTransportMovementsFilters(formattedTransportMovements),
    [formattedTransportMovements],
  );

  const filteredTransportMovements = useMemo(
    () => filterTransportMovements(formattedTransportMovements, filterValues),
    [formattedTransportMovements, filterValues],
  );

  const handleFiltersChange = (filters: TransportMovementFiltersValues) => {
    setFilterValues(filters);
  };

  if (isLoading) return <Loader />;

  return (
    <TransportMovementContainer fluid>
      <TransportMovementFilters
        initData={transportMovementsFiltersData}
        onChange={handleFiltersChange}
      />
      <Title
        title={t('transportSummary')}
        icon={Icons.shortTransporter}
        color={transportMovementLink}
      />
      <TransportDataGrid dataSource={filteredTransportMovements} />
    </TransportMovementContainer>
  );
};
