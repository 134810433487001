import { VesselMovement, VesselPositions } from '@/types';
import {
  FormattedVesselsMovementData,
  VesselFiltersInitData,
  VesselFiltersValues,
} from './types';

const findVesselRowIndex = (
  rows: FormattedVesselsMovementData[],
  item: VesselMovement,
) =>
  rows.findIndex(
    (row) =>
      row.vesselId === item.vesselId &&
      row.despatchDate === item.despatchDate &&
      row.etaDate === item.etaDate &&
      row.locationFrom === item.locationFrom &&
      row.locationTo === item.locationTo,
  );

export const formatVesselMovements = (
  vesselMovementsEntities: VesselMovement[],
) =>
  vesselMovementsEntities.reduce((result, item) => {
    const vesselRowIndex = findVesselRowIndex(result, item);
    const {
      vesselId,
      vessel,
      despatchDate,
      etaDate,
      locationFrom,
      locationTo,
      productDesc,
      mmsi,
    } = item;
    if (vesselRowIndex === -1) {
      return [
        ...result,
        {
          vesselId,
          vessel,
          despatchDate,
          etaDate,
          locationFrom,
          locationTo,
          quantity: 1,
          productDesc,
          mmsi,
          items: [item],
        },
      ];
    }

    const updatedResult = [...result];
    updatedResult[vesselRowIndex].items.push(item);
    updatedResult[vesselRowIndex].quantity =
      updatedResult[vesselRowIndex].items.length;

    return updatedResult;
  }, [] as FormattedVesselsMovementData[]);

export const getVesselMovementsFilters = (
  vesselMovementsData: FormattedVesselsMovementData[],
) => {
  const initialFiltersData: VesselFiltersInitData = {
    locationFromItems: [],
    locationToItems: [],
    modelItems: [],
  };

  return vesselMovementsData.reduce((result, item) => {
    const updatedResult = { ...result };

    if (!result.locationFromItems.includes(item.locationFrom)) {
      updatedResult.locationFromItems.push(item.locationFrom);
    }

    if (!result.locationToItems.includes(item.locationTo)) {
      updatedResult.locationToItems.push(item.locationTo);
    }

    if (!result.modelItems.includes(item.productDesc)) {
      updatedResult.modelItems.push(item.productDesc);
    }

    return updatedResult;
  }, initialFiltersData);
};

export const formatDate = (dateValue?: string) => {
  if (!dateValue) {
    return undefined;
  }
  const date = new Date(dateValue);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);

  return `${year}-${month}-${day}`;
};

export const filterVesselMovements = (
  vesselMovementsData: FormattedVesselsMovementData[],
  selectedFilters: VesselFiltersValues,
) =>
  vesselMovementsData.filter((item) => {
    const comparisonResults: boolean[] = [];

    if (selectedFilters.locationFrom) {
      comparisonResults.push(
        selectedFilters.locationFrom === item.locationFrom,
      );
    }

    if (selectedFilters.locationTo) {
      comparisonResults.push(selectedFilters.locationTo === item.locationTo);
    }

    if (selectedFilters.model) {
      comparisonResults.push(selectedFilters.model === item.productDesc);
    }

    if (selectedFilters.despatchDate) {
      comparisonResults.push(
        selectedFilters.despatchDate === formatDate(item.despatchDate),
      );
    }

    if (selectedFilters.etaDate) {
      comparisonResults.push(
        selectedFilters.etaDate === formatDate(item.etaDate),
      );
    }

    return !comparisonResults.includes(false);
  });

export const getFilteredVesselPositions = (
  vesselPositionsEnities: VesselPositions[],
  selectedMmsi: number[] | null,
  filteredVesselMovements: FormattedVesselsMovementData[],
) =>
  selectedMmsi
    ? vesselPositionsEnities.filter((position) =>
        selectedMmsi.some((mmsi) => mmsi.toString() === position.mmsi),
      )
    : vesselPositionsEnities.filter((position) =>
        filteredVesselMovements
          .map(({ mmsi }) => mmsi)
          .some((mmsi) => mmsi.toString() === position.mmsi),
      );
