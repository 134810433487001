import React, { FC } from 'react';
import { useMsal } from '@azure/msal-react';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { Dropdown } from '@/ui';
import { b2cPolicies, deployment } from '@/auth/config';
import { TenantSwitcherContainer } from './TenantSwitcher.styled';

export const TenantSwitcher: FC = () => {
  const { accounts, instance } = useMsal();
  const idTokenClaims = useIdTokenClaims();
  const allTenants = idTokenClaims?.allTenants || [];

  if (allTenants.length < 2) {
    return null;
  }

  const handleTenantChange = (tenantId: string) => {
    instance.loginRedirect({
      authority: b2cPolicies.authorities.signIn.authority,
      scopes: [
        'openid',
        'profile',
        `https://${deployment.b2cTenantName}.onmicrosoft.com/mtrest/User.Invite`,
        `https://${deployment.b2cTenantName}.onmicrosoft.com/mtrest/User.ReadAll`,
      ],
      account: accounts[0],
      extraQueryParameters: { tenant: tenantId },
    });
  };

  return (
    <TenantSwitcherContainer className="pb-lg-0 pb-2">
      <Dropdown>
        <Dropdown.Toggle variant="secondary">
          {idTokenClaims?.appTenantName}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {allTenants.map((tenant) => {
            const [id, name] = tenant.split(';');
            return (
              <Dropdown.Item
                key={id}
                eventKey={id}
                active={idTokenClaims?.appTenantName === name}
                as="div"
                onClick={() => handleTenantChange(id)}
              >
                {name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </TenantSwitcherContainer>
  );
};
