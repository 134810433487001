import React, { FC } from 'react';
import { Col } from '@/ui';
import { Icon, Icons, IconDefinition, faFileExcel } from '@/ui/components/Icon';
import {
  TitleRow,
  AdditionalIcon,
  MainIcon,
  TitleText,
  Text,
} from './Title.styled';

type TitleProps = {
  title: string;
  icon: IconDefinition | Icons;
  isAdditionIcon?: boolean;
  color?: string;
};

export const Title: FC<TitleProps> = ({
  title,
  icon,
  isAdditionIcon = false,
  color,
}) => (
  <TitleRow>
    <Col lg={5} md={6} sm={10}>
      <TitleText>
        <MainIcon color={color}>
          <Icon icon={icon} />
        </MainIcon>
        <Text>{title}</Text>
      </TitleText>
    </Col>
    {isAdditionIcon ? (
      <Col lg={7} md={6} sm={2}>
        <AdditionalIcon>
          <Icon icon={faFileExcel} />
        </AdditionalIcon>
      </Col>
    ) : null}
  </TitleRow>
);
