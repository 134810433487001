import React, { FC, useCallback, useState } from 'react';
import { Card, Col, DevButton, Row, TextArea } from '@/ui';
import { PageContainer } from '@/ui/components/Page';
import { useTranslate } from '@/translations';
import { AddImagePopup } from '@/modules/Inspection/components/tabs/Damage/components/AddImagePopup';
import {
  useDeleteInspectionDamagesImages,
  useInspectionDamagesImages,
} from '@/api/Inspections/hooks/useInspectionsVehicles';
import { DamageActions } from '@/modules/Inspection/components/tabs/Damage/components/DamageActions';

import { DamageActionsDataGridSectionType } from './types';

export const DamageMasterDetail: FC<DamageActionsDataGridSectionType> = ({
  vehicleDamageId,
  image,
  note,
  authorizationNotes,
  masterDetailsDataGridProps,
}) => {
  const { t } = useTranslate();

  const { data: damagesImagesData, isLoading: isDamagesImagesLoading } =
    useInspectionDamagesImages(vehicleDamageId);
  const { mutate: deleteDamagesImagesMutate } =
    useDeleteInspectionDamagesImages();

  const [isImagePopupVisible, setImagePopupVisibility] = useState(false);

  const toggleImagePopup = useCallback(() => {
    setImagePopupVisibility(!isImagePopupVisible);
  }, [isImagePopupVisible]);

  return (
    <Row>
      <Col lg={6} className="mt-3 mt-lg-0">
        <Card>
          <DamageActions
            vehicleDamageId={vehicleDamageId}
            masterDetailsDataGridProps={masterDetailsDataGridProps}
          />
        </Card>

        <Card className="mt-3">
          <PageContainer fluid>
            <h2>{t('notes')}</h2>

            <TextArea className="text-wrap opacity-100" value={note} disabled />
          </PageContainer>
        </Card>

        {authorizationNotes && (
          <Card className="mt-3">
            <PageContainer fluid>
              <h2>{t('authorizationNotes')}</h2>

              <TextArea
                className="text-wrap opacity-100"
                value={authorizationNotes}
                disabled
              />
            </PageContainer>
          </Card>
        )}
      </Col>
      <Col lg={6}>
        <Card className="h-100">
          <PageContainer fluid>
            <h2>{t('images')}</h2>

            <div className="my-3 d-flex justify-content-start">
              <DevButton
                stylingMode="outlined"
                icon="add"
                onClick={toggleImagePopup}
              />
            </div>

            <AddImagePopup
              isPopupVisible={isImagePopupVisible}
              onTogglePopup={toggleImagePopup}
              vehicleDamageId={vehicleDamageId}
            />

            <div className="d-flex flex-column m-0 h-">
              <img
                src={image}
                alt="damage"
                loading="lazy"
                style={{ maxHeight: '40vh', objectFit: 'cover' }}
              />
            </div>

            {!isDamagesImagesLoading &&
              damagesImagesData?.entities?.map(({ id, imageFileUrl }) => (
                <div key={id} className="d-flex flex-column mt-3">
                  <img
                    src={imageFileUrl}
                    alt="damage"
                    loading="lazy"
                    style={{ maxHeight: '40vh', objectFit: 'cover' }}
                  />

                  <DevButton
                    className="text-muted mt-3 w-100"
                    text={t('delete')}
                    stylingMode="contained"
                    icon="trash"
                    onClick={() => deleteDamagesImagesMutate(id)}
                  />
                </div>
              ))}
          </PageContainer>
        </Card>
      </Col>
    </Row>
  );
};
