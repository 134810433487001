import React, { FC } from 'react';
import { TextBox } from '@/ui';

export const Field: FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <div className="dx-fieldset mx-0">
    <div className="dx-field">
      <div className="dx-field-label">{label}</div>
      <div className="dx-field-value">
        <TextBox
          readOnly
          inputAttr={{ 'aria-label': label }}
          hoverStateEnabled={false}
          value={value}
        />
      </div>
    </div>
  </div>
);
