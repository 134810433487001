import { AxiosPromise } from 'axios';
import {
  PassportsData,
  PostPassportBody,
  PassportsTransactionsData,
  PostPassportsTransactionsBody,
} from '@/types';
import { Core, PASSPORTS, PASSPORTS_TRANSACTIONS } from '../Core';

type PostPassports = (body: PostPassportBody) => AxiosPromise<PassportsData>;
type PostPassportsTransactions = (
  body: PostPassportsTransactionsBody,
) => AxiosPromise<PassportsTransactionsData>;

class Passport extends Core {
  postPassports: PostPassports = async (body) =>
    this.post<PostPassportBody, AxiosPromise<PassportsData>>(PASSPORTS(), body);

  postPassportsTransactions: PostPassportsTransactions = async (body) =>
    this.post<PostPassportsTransactionsBody, AxiosPromise<PassportsTransactionsData>>(PASSPORTS_TRANSACTIONS(), body);
}

export const passport = new Passport();
