import { useEffect, useRef } from 'react';
import type { ExpandedRows } from '@/modules/Inspection/components/tabs/Damage/components/useDataGridExpandedState';

export const useDataGridScroll = ({
  dataSource,
  expandedRows,
  shouldScroll = true,
}: {
  // eslint-disable-next-line
  dataSource?: any[];
  expandedRows: ExpandedRows;
  shouldScroll?: boolean;
}) => {
  const ref = useRef(null);

  // rerender
  useEffect(() => {
    const needToExpand = Object.values(expandedRows).some(Boolean);

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const { _element: element, _instance: { expandRow, isRowExpanded } }: any = ref.current;

    if (needToExpand) {
      dataSource?.forEach((row) => {
        if (expandedRows[row.id] && !isRowExpanded(row)) {
          expandRow(row);
        }
      });

      if (shouldScroll) {
        element.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, [dataSource, expandedRows, shouldScroll]);

  return {
    ref,
  };
};
