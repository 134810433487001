import { CreateUser } from '@/types';

import { AddUserData } from './Users/types';

export const formatCreateUserData = (data: AddUserData): CreateUser => ({
  displayName: data.displayName,
  email: data.email,
  role: data.role.id,
  inspectorId: data.inspectorId,
});
