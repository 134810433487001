import { CreateTenant, Tenant, UpdateTenant } from '@/types';

export const formatCreateTenantData = (data: Tenant): CreateTenant => ({
  name: data.name,
  color1: data.color1 ?? null,
  color2: data.color2 ?? null,
  iconBase64: data.iconUrl ?? null,
  isActive: data.isActive,
});

export const formatUpdateTenantData = (
  newData: Partial<Tenant>,
  oldData: Tenant,
): UpdateTenant => ({
  id: oldData.id,
  isActive: {
    isChanged: newData.isActive !== undefined,
    value: newData.isActive ?? oldData.isActive,
  },
  name: {
    isChanged: newData.name !== undefined,
    value: newData.name ?? oldData.name,
  },
  color1: {
    isChanged: newData.color1 !== undefined,
    value: newData.color1 ?? oldData.color1 ?? null,
  },
  color2: {
    isChanged: newData.color2 !== undefined,
    value: newData.color2 ?? oldData.color2 ?? null,
  },
  iconBase64: {
    isChanged: newData.iconUrl !== null,
    value: newData.iconUrl ?? oldData.iconUrl ?? null,
  },
});
