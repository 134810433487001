import React, { FC } from 'react';
import { PageContainer } from '@/ui/components/Page';
import { PointInTimeReportsDataGrid } from './components/PointInTimeReportsDataGrid';
import { InspectionPageTab } from '../types';

const mockData = [
  {
    id: 0,
    date: 'Front',
    event: 'Inspected',
    image: '',
  },
  {
    id: 1,
    date: 'Front',
    event: 'Inspecting',
    image: '',
  },
  {
    id: 2,
    date: 'Front',
    event: 'In Progress',
    image: '',
  },
];

export const PointInTimeReports: FC<InspectionPageTab> = () => (
  <PageContainer fluid>
    <PointInTimeReportsDataGrid dataSource={mockData} />
  </PageContainer>
);
