import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { hasRoleAccess } from '@/utils/access';
import { AppEntities, AppRoles } from '@/types';
import { routes } from '@/routes';

interface UseGetPathToDocumentManagementResponse {
  getDocumentManagementPath: (data?: {
    modal?: string;
    entity?: AppEntities;
    entityReference?: string;
  }) => string;
  hasAdminAccess: boolean;
}

type UseGetPathToDocumentManagement =
  () => UseGetPathToDocumentManagementResponse;

export const useGetPathToDocumentManagement: UseGetPathToDocumentManagement =
  () => {
    const idTokenClaims = useIdTokenClaims();
    const userRoles = idTokenClaims?.roles || [];
    const hasAdminAccess = hasRoleAccess(userRoles, [
      AppRoles.Admin,
      AppRoles.GbaAdmin,
      AppRoles.GbaMember,
      AppRoles.GbaSuperAdmin,
    ]);

    const baseUrl = hasAdminAccess
      ? routes.adminDocumentManagement
      : routes.documentManagement;

    const getDocumentManagementPath: UseGetPathToDocumentManagementResponse['getDocumentManagementPath'] =
      (data) => {
        const searchParams = new URLSearchParams(data);

        return `${baseUrl}${searchParams ? `?${searchParams.toString()}` : ''}`;
      };

    return {
      getDocumentManagementPath,
      hasAdminAccess,
    };
  };
