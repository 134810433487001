import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import {
  Row,
  FileUploader,
  FileLoadPanel,
  UploadedEvent,
  ValueChangedEvent,
} from '@/ui';
import { Title } from '@/ui/components/Title';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { faCalendarDays } from '@/ui/components/Icon';
import { PageContainer } from '@/ui/components/Page';
import {
  useShippingPlan,
  useUploadShippingPlan,
  useDeleteShippingPlan,
} from '@/api/ShippingPlan';
import { Loader } from '@/ui/components/Loader';
import { acceptFiles, allowedFileExtensions } from '@/modules/UploadFile/utils';
import { MAX_FILE_SIZE } from '@/utils/constants';
import { removeExtension } from '@/utils/helpers';
import { AdminShippingPlanDataGrid } from './components/ShippingPlanDataGrid';

export const AdminShippingPlan = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();
  const idTokenClaims = useIdTokenClaims();
  const tenant = idTokenClaims?.appTenantId;
  const [file, setFile] = useState<ArrayBuffer | string | null>(null);
  const [fileName, setFileName] = useState('');
  const { data, isLoading } = useShippingPlan(tenant);
  const { mutate: uploadMutate, isLoading: isUploadLoading } =
    useUploadShippingPlan();
  const { mutate: deleteMutate } = useDeleteShippingPlan();

  if (isLoading) return <Loader />;

  const uploadFile = () => {
    uploadMutate({
      data: { shippingPlanFileBase64: file, fileName, tenantId: tenant },
    });
  };

  const onValueChanged = (e: ValueChangedEvent) => {
    const item: File = e.value?.[0]!;

    if (!item) return;
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (target?.result) {
        setFile(target.result);
        setFileName(removeExtension(item.name));
      }
    };
    reader.readAsDataURL(item); // convert to base64 string
  };

  const onUploaded = (e: UploadedEvent) => e.component.reset();

  return (
    <PageContainer fluid>
      <Title
        title={t('shippingPlanAdmin')}
        icon={faCalendarDays}
        color={accent}
      />

      <Row>
        <FileLoadPanel visible={isUploadLoading} />
        <FileUploader
          accept={acceptFiles}
          uploadMode="useButtons"
          allowedFileExtensions={allowedFileExtensions(acceptFiles)}
          uploadFile={uploadFile}
          onValueChanged={onValueChanged}
          selectButtonText={t('selectFile')}
          uploadButtonText={t('upload')}
          invalidMaxFileSizeMessage={t('fileIsTooLarge')}
          maxFileSize={MAX_FILE_SIZE}
          onUploaded={onUploaded}
        />
      </Row>

      <AdminShippingPlanDataGrid
        dataSource={data?.entities}
        deleteShippingPlan={deleteMutate}
      />
    </PageContainer>
  );
};
