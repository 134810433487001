import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row, SelectBox } from '@/ui';
import { useTranslate } from '@/translations';
import { Title } from '@/ui/components/Title';
import { Button } from '@/ui/components/Button';
import { Icons } from '@/ui/components/Icon';
import { Loader } from '@/ui/components/Loader';
import {
  usePipelineLocations,
  usePipelineGraph,
  // usePipelines,
  usePipelineStatus} from '@/api/Pipelines';
import { PipelineChart } from './components/PipelineChart';
import { PipelineDataGrid } from './components/PipelineDataGrid';
import { PipelineContainer } from './Pipeline.styled';
import { filterStatusData, formatStatusData } from './utils';
import { LocationFiltersValues } from './types';

interface PipelineLocation {
  state?: {
    locationId: string;
  };
}

export const Pipeline = () => {
  const { t } = useTranslate();
  const location = useLocation() as PipelineLocation;
  const stateLocationId = location.state?.locationId ?? '';
  const [locationId, setLocationId] = useState(stateLocationId);
  const [filterValues, ] =
    useState<LocationFiltersValues>({});
  const [submittedLocationId, setSubmittedLocationId] =
    useState(stateLocationId);
    const [, setSelectedUnitName] = useState<string[] | null>(
      null,
    );
  const { data: locationsData, isLoading: isLocationsLoading } =
    usePipelineLocations();
  const { data: graphData, isLoading: isGraphLoading } = usePipelineGraph();
  // const { data: pipelinesData, isLoading: isPipelineLoading } =
  //   usePipelines(submittedLocationId);
  const {data: statusData, isLoading: isStatusLoading} = usePipelineStatus(submittedLocationId, {enabled: true});

  const formattedStates = useMemo(
    () => formatStatusData(statusData?.statuses || []),
    [statusData?.statuses],
  );

  // const statusFiltersData = useMemo(
  //   () => getStatusFilters(formattedStates),
  //   [formattedStates],
  // );
  const filteredStatuses = useMemo(
    () => filterStatusData(formattedStates, filterValues),
    [formattedStates, filterValues],
  );
  useEffect(() => {
    setSelectedUnitName(filteredStatuses.map((mov) => mov.statusCode));
  }, [filteredStatuses]);

  const onClick = () => {
    setSubmittedLocationId(locationId);
  };

  // const handleFiltersChange = (filters: LocationFiltersValues) => {
  //   setFilterValues(filters);
  // };
  const handleSelectStatusGroup = useCallback((status: string | null) => {
    setSelectedUnitName(status ? [status] : null);
  }, []);
  if (isLocationsLoading || isGraphLoading  || isStatusLoading)
    return <Loader />;

  return (
    <PipelineContainer fluid>
      <Row className="align-items-center">
        <Col>
          <SelectBox
            items={locationsData?.entities}
            value={locationId}
            placeholder={t('chooseLocation')}
            label={t('location')}
            displayExpr="city"
            valueExpr="id"
            onValueChange={(e) => setLocationId(e)}
            showClearButton
          />
        </Col>
        <Col>
          <Button fullWidth="true" title={t('go')} onClick={onClick} />
        </Col>
      </Row>
      <Title title={t('productPipeline')} icon={Icons.compound} /> 
      <Row>
        <Col lg={6} md={12} sm={12}>
          <PipelineDataGrid statusDetailData={filteredStatuses} onSelectStatusGroup={handleSelectStatusGroup} />
        </Col>
        <Col lg={6} md={12} sm={12} className="mt-5 mt-lg-0">
          <PipelineChart graphData={graphData?.entities} />
        </Col>
      </Row>
    </PipelineContainer>
  );
};
