import { User, CreateUser, UpdateUser, Role } from '@/types';

export const formatCreateUserData = (data: {
  displayName: string;
  email: string;
  role: Role;
  inspectorId: number;
}): CreateUser => ({
  displayName: data.displayName,
  email: data.email,
  role: data.role.id,
  inspectorId: data.inspectorId,
});

export const formatUpdateUserData = (
  newData: Partial<User>,
  oldData: User,
): UpdateUser => ({
  id: oldData.id,
  role: {
    isChanged: newData.role?.id !== undefined,
    value: newData.role?.id ?? oldData.role.id,
  },
  displayName: {
    isChanged: newData.displayName !== undefined,
    value: newData.displayName ?? oldData.displayName,
  },
});
