import React from 'react';
import { useTranslate } from '@/translations';
import { faListCheck } from '@/ui/components/Icon';
import { Title } from '@/ui/components/Title';
import { PageContainer } from '@/ui/components/Page';
import { useTheme } from 'styled-components';
import { BackButton } from '@/ui/components/BackButton';
import { routes } from '@/routes';
import { TabPanel, TabPanelItem } from '@/ui';
import { DamagesDataGrid } from './components/DamagesDataGrid';

const mockData = [
  {
    id: 2,
    description: 'STANDARD',
    inheritedForm: '',
    associatedTemplates: 0,
  },
  {
    id: 3,
    description: 'Standard 26.06.2022',
    inheritedForm: '',
    associatedTemplates: 2,
  },
];

export const Damages = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();

  return (
    <PageContainer fluid>
      <Title title={t('damage')} icon={faListCheck} color={accent} />
      <BackButton linkTo={`${routes.systemAdministration}`} />

      <TabPanel className="mt-3">
        <TabPanelItem title={t('repairMatrices')}>
          <DamagesDataGrid dataSource={mockData} />
        </TabPanelItem>
      </TabPanel>
    </PageContainer>
  );
};
