import React from 'react';
import styled from 'styled-components';
import { Coords } from 'google-map-react';
import { Icon, Icons, IconDefinition } from '@/ui/components/Icon';

type MapMarkerProps = Coords & {
  icon: Icons | IconDefinition;
};

const MapMarkerContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.white};
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MapMarker = ({ icon }: MapMarkerProps) => (
  <MapMarkerContainer>
    <Icon icon={icon} />
  </MapMarkerContainer>
);
