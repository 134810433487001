import { defaultTheme } from '@/ui/theme';
import {
  Workbook,
  exportDataGrid,
  Worksheet,
  saveAs,
  ExportingEvent,
} from '@/ui';
import { TransportMovement } from '@/types';
import { FormattedTransportMovementData } from '../../types';

const rowHeight = 20;

const borderStyle = {
  style: 'thin',
  color: { argb: defaultTheme.mainExcelBorderColor },
} as const;

const fillStyle = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: defaultTheme.mainExcelHeaderColor },
} as const;

const bordersStyle = {
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
  top: borderStyle,
};

const alignmentStyle = {
  horizontal: 'center',
} as const;

const fontStyle = {
  bold: true,
};

export const transportMovementExcelConfiguration = {
  columns: {
    orderReference: 'Order Reference',
    serialNumber: 'Serial Number',
    productCode: 'Product Code',
    productDesc: 'Product Desc',
    colourCode: 'Colour Code',
    colourDesc: 'Colour Desc',
  },
  height: rowHeight,
  fill: fillStyle,
  border: bordersStyle,
  alignment: alignmentStyle,
  font: fontStyle,
};

export interface MasterRow {
  rowIndex: number;
  data: FormattedTransportMovementData;
}

const insertRow = (
  worksheet: Worksheet,
  index: number,
  offset: number,
  outlineLevel: number,
) => {
  const workSheet = worksheet;
  const currentIndex = index + offset;
  const row = workSheet.insertRow(currentIndex, [], 'n');

  for (let i = worksheet.rowCount + 1; i > currentIndex; i -= 1) {
    workSheet.getRow(i).outlineLevel = worksheet.getRow(i - 1).outlineLevel;
  }
  row.outlineLevel = outlineLevel;
  return row;
};

/* eslint-disable no-param-reassign */
export const handleExport = (
  e: ExportingEvent<FormattedTransportMovementData>,
) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');
  const masterRows: MasterRow[] = [];

  exportDataGrid({
    component: e.component,
    worksheet,
    topLeftCell: { row: 1, column: 1 },
    customizeCell: ({ gridCell, excelCell }) => {
      if (
        gridCell?.column?.dataField === 'movementRef' &&
        gridCell.rowType === 'data'
      ) {
        masterRows.push({
          rowIndex: excelCell.fullAddress.row + 1,
          data: gridCell.data,
        });
      }
    },
  })
    .then((cellRange) => {
      const headerTitle = worksheet.getRow(1);
      headerTitle.height = transportMovementExcelConfiguration.height;
      headerTitle.eachCell((headerTitleCell) => {
        headerTitleCell.style = {
          fill: transportMovementExcelConfiguration.fill,
          border: transportMovementExcelConfiguration.border,
          alignment: transportMovementExcelConfiguration.alignment,
        };
      });

      let offset = 0;

      masterRows.forEach((masterRow, index) => {
        const columnIndex = (cellRange.from?.column || 0) + 1;

        const transportMovementItemsData = masterRow.data.items;
        const masterGridRowIndex = masterRow.rowIndex + index;

        const masterHeaderRow = insertRow(
          worksheet,
          masterGridRowIndex,
          offset,
          2,
        );
        masterHeaderRow.height = transportMovementExcelConfiguration.height;

        offset += 1;

        Object.values(transportMovementExcelConfiguration.columns).forEach(
          (columnName, currentColumnIndex) => {
            Object.assign(
              masterHeaderRow.getCell(columnIndex + currentColumnIndex),
              {
                value: columnName,
                fill: transportMovementExcelConfiguration.fill,
                border: transportMovementExcelConfiguration.border,
                alignment: transportMovementExcelConfiguration.alignment,
                font: transportMovementExcelConfiguration.font,
              },
            );
          },
        );

        transportMovementItemsData.forEach((transportMovement) => {
          const masterRecordRow = insertRow(
            worksheet,
            masterGridRowIndex,
            offset,
            2,
          );
          offset += 1;

          Object.keys(transportMovementExcelConfiguration.columns).forEach(
            (columnName, currentColumnIndex) => {
              const columnValue =
                transportMovement[columnName as keyof TransportMovement];
              Object.assign(
                masterRecordRow.getCell(columnIndex + currentColumnIndex),
                {
                  value: columnValue,
                },
              );
            },
          );
        });
        offset -= 1;
      });
    })
    .then(() => {
      workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Transport Movement.xlsx',
        );
      });
    });
  e.cancel = true;
};
