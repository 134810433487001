import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { TENANTS } from '@/utils/constants';

export const useTenant = () => {
  const idTokenClaims = useIdTokenClaims();

  return {
    appTenantName: idTokenClaims?.appTenantName,
    isDefaultTenant: idTokenClaims?.appTenantName === TENANTS.DEFAULT, // appTenantId: 1
    isBMWTenant: idTokenClaims?.appTenantName === TENANTS.BMW, // appTenantId: 17
  }
}
