import { AxiosPromise } from 'axios';
import { FunctionalFeature, FunctionalFeaturesData } from '@/types';
import { Core, FUNCTIONAL_FEATURES, TENANTS } from '../Core';

interface ToggleEnableFunctionalFeatureData {
  tenantId: number;
  featureId: number;
  isEnabled: boolean;
}

type GetFunctionalFeatures = () => AxiosPromise<FunctionalFeature>;
type GetTenantFunctionalFeatures = (
  tenantId: number,
) => AxiosPromise<FunctionalFeaturesData>;
type ToggleDisableFunctionalFeature = (
  data: ToggleEnableFunctionalFeatureData,
) => AxiosPromise<boolean>;

class FunctionalFeatures extends Core {
  getFunctionalFeatures: GetFunctionalFeatures = async () =>
    this.get(FUNCTIONAL_FEATURES());

  getTenantFunctionalFeatures: GetTenantFunctionalFeatures = async (tenantId) =>
    this.get(`${TENANTS(tenantId)}/functionalFeatures`);

  toggleDisableFunctionalFeature: ToggleDisableFunctionalFeature = async ({
    tenantId,
    featureId,
    isEnabled,
  }: ToggleEnableFunctionalFeatureData) =>
    this.patch<boolean, AxiosPromise<boolean>>(
      `${TENANTS(
        tenantId,
      )}/functionalFeatures/${featureId}/enable/${isEnabled}`,
    );
}

export const functionalFeatures = new FunctionalFeatures();
