import { defaultTheme } from '@/ui/theme';
import {
  Workbook,
  exportDataGrid,
  Worksheet,
  saveAs,
  ExportingEvent,
} from '@/ui';
import { OrderSummary } from '@/types';
import { FormattedOrderSummaryData } from '../../types';

const rowHeight = 20;

const borderStyle = {
  style: 'thin',
  color: { argb: defaultTheme.mainExcelBorderColor },
} as const;

const fillStyle = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: defaultTheme.mainExcelHeaderColor },
} as const;

const bordersStyle = {
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
  top: borderStyle,
};

const alignmentStyle = {
  horizontal: 'left',
  vertical: 'middle',
} as const;

const fontStyle = {
  bold: true,
};

export const orderExcelConfiguration = {
  columns: {
    billOfLading: 'Bill of Lading',
    volume: 'Volume',
    portOfLoading: 'Port of Loading',
    portOfDestination: 'Port of Destination',
    transitId: 'Transit',
    despatchDate: 'Despatched Date',
    estimatedArrival: 'Estimated Arrival',
    arrivalDate: 'Arrival Date',
  },
  height: rowHeight,
  fill: fillStyle,
  border: bordersStyle,
  alignment: alignmentStyle,
  font: fontStyle,
};

export interface MasterRow {
  rowIndex: number;
  data: FormattedOrderSummaryData;
}

const insertRow = (
  worksheet: Worksheet,
  index: number,
  offset: number,
  outlineLevel: number,
) => {
  const workSheet = worksheet;
  const currentIndex = index + offset;
  const row = workSheet.insertRow(currentIndex, [], 'n');

  for (let i = worksheet.rowCount + 1; i > currentIndex; i -= 1) {
    workSheet.getRow(i).outlineLevel = worksheet.getRow(i - 1).outlineLevel;
  }
  row.outlineLevel = outlineLevel;
  return row;
};

/* eslint-disable no-param-reassign */
export const handleExport = (e: ExportingEvent<FormattedOrderSummaryData>) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet');
  const masterRows: MasterRow[] = [];

  exportDataGrid({
    component: e.component,
    worksheet,
    topLeftCell: {
      row: 1,
      column: 1,
    },
    customizeCell: ({ gridCell, excelCell }) => {
      if (
        gridCell?.column?.dataField === 'consigneeCode' &&
        gridCell.rowType === 'data'
      ) {
        masterRows.push({
          rowIndex: excelCell.fullAddress.row + 1,
          data: gridCell.data,
        });
      }
    },
  })
    .then((cellRange) => {
      const headerTitle = worksheet.getRow(1);
      headerTitle.height = orderExcelConfiguration.height;
      worksheet.getColumn(1).width = 40;
      worksheet.getColumn(2).width = 40;
      worksheet.properties.defaultColWidth = 16;

      headerTitle.eachCell((headerTitleCell) => {
        headerTitleCell.style = {
          fill: orderExcelConfiguration.fill,
          border: orderExcelConfiguration.border,
          alignment: orderExcelConfiguration.alignment,
        };
      });

      let offset = 0;

      masterRows.forEach((masterRow, index) => {
        const columnIndex = (cellRange.from?.column || 0) + 1;

        const orderItemsData = masterRow.data.items;
        const masterGridRowIndex = masterRow.rowIndex + index;

        const masterHeaderRow = insertRow(
          worksheet,
          masterGridRowIndex,
          offset,
          2,
        );
        masterHeaderRow.height = orderExcelConfiguration.height;

        offset += 1;

        Object.values(orderExcelConfiguration.columns).forEach(
          (columnName, currentColumnIndex) => {
            Object.assign(
              masterHeaderRow.getCell(columnIndex + currentColumnIndex),
              {
                value: columnName,
                fill: orderExcelConfiguration.fill,
                border: orderExcelConfiguration.border,
                alignment: orderExcelConfiguration.alignment,
                font: orderExcelConfiguration.font,
              },
            );
          },
        );

        orderItemsData.forEach((orderSummary) => {
          const masterRecordRow = insertRow(
            worksheet,
            masterGridRowIndex,
            offset,
            2,
          );
          offset += 1;

          Object.keys(orderExcelConfiguration.columns).forEach(
            (columnName, currentColumnIndex) => {
              const dateColumns = [
                'despatchDate',
                'estimatedArrival',
                'arrivalDate',
              ];
              const columnValue =
                orderSummary[columnName as keyof OrderSummary];

              const date = columnValue
                ? new Date(columnValue).toLocaleDateString('en-GB')
                : columnValue;

              const formattedValue = dateColumns.includes(columnName)
                ? date
                : columnValue;

              Object.assign(
                masterRecordRow.getCell(columnIndex + currentColumnIndex),
                {
                  value: formattedValue,
                  alignment: orderExcelConfiguration.alignment,
                },
              );
            },
          );
        });
        offset -= 1;
      });
    })
    .then(() => {
      workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Order.xlsx',
        );
      });
    });
  e.cancel = true;
};
