import { AxiosPromise } from 'axios';
import { TransportMovementsData, TransportPositionsData } from '@/types';
import { Core, TRANSPORT_MOVEMENTS, TRANSPORT_POSITIONS } from '../Core';

type GetTransportMovements = () => AxiosPromise<TransportMovementsData>;
type GetTransportPositions = () => AxiosPromise<TransportPositionsData>;

class TransportMovements extends Core {
  getTransportMovements: GetTransportMovements = async () =>
    this.get(TRANSPORT_MOVEMENTS());

  getTransportPositions: GetTransportPositions = async () =>
    this.get(TRANSPORT_POSITIONS());
}

export const transportMovements = new TransportMovements();
