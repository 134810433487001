import { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TranslationsContext } from './constants';
import { PrimitiveType, TranslationKey } from './types';

export type TFunc = (message: TranslationKey, values?: Record<string, PrimitiveType>) => string;

export const useTranslate = () => {
  const context = useContext(TranslationsContext);

  if (!context) {
    throw new Error('useTranslation should be used within a TranslationsContext');
  }

  const { language, setLanguage } = context;
  const intlObj = useIntl();

  const t: TFunc = useCallback(
    (message, values) => {
      if (!message) return '';

      return intlObj.formatMessage({ id: message }, values);
    },
    [intlObj],
  );

  return useMemo(() => ({ t, language, setLanguage }), [
    t,
    language,
    setLanguage,
  ]);
};
