import { AxiosPromise } from 'axios';
import { PTSData } from '@/types';
import { Core, PTS } from '../Core';

type GetPTSData = () => AxiosPromise<PTSData>;

class Pts extends Core {
    getPTSData: GetPTSData = async () => this.get(PTS());
}

export const pts = new Pts();