import { AxiosPromise } from 'axios';
import { VinMovementData, VinPositionsData } from '@/types';
import { Core, VIN_MOVEMENTS, VIN_POSITIONS } from '../Core';

type GetVinMovements = () => AxiosPromise<VinMovementData>;
type GetVinPositions = (vin?: string) => AxiosPromise<VinPositionsData>;

class Vin extends Core {
  getVinMovements: GetVinMovements = async () => this.get(VIN_MOVEMENTS());

  getVinPositions: GetVinPositions = async (vin) =>
    this.get(VIN_POSITIONS(vin));
}

export const vin = new Vin();
