import React, { FC, useCallback } from 'react';
import { useTranslate } from '@/translations';
import { DataGrid, Paging, Pager, Column, FilterRow } from '@/ui';
import { faDownload, Icon } from '@/ui/components/Icon';
import { StyledLinkContainer } from '@/ui/globalStyles';
import { FORMAT_DATE } from '@/utils/constants';
import { isPagerVisible } from '@/utils/helpers';
import { OrderGridDetailSectionProps, RenderCellProps } from './types';

const PAGE_SIZE = 6;

export const OrderGridDetailSection: FC<OrderGridDetailSectionProps> = ({
  dataSource,
}) => {
  const { t } = useTranslate();

  const onRenderCell = useCallback(
    ({ value, data: { billOfLading } }: RenderCellProps) =>
      value ? (
        <StyledLinkContainer>
          <a href={value} download={billOfLading} aria-label="Download">
            <Icon icon={faDownload} />
          </a>
        </StyledLinkContainer>
      ) : (
        <StyledLinkContainer>
          <span>{t('notApplicable')}</span>
        </StyledLinkContainer>
      ),
    [t],
  );

  return (
    <DataGrid
      dataSource={dataSource}
      columnHidingEnabled
      showRowLines
      width="100%"
      showBorders
      allowColumnReordering
    >
      <FilterRow visible />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />
      <Column
        dataField="billOfLading"
        caption="Bill of Lading"
        minWidth={260}
        width={260}
      />
      <Column
        dataField="volume"
        caption="Volume"
        minWidth={100}
        width={100}
        alignment="left"
        hidingPriority={8}
      />
      <Column
        dataField="portOfLoading"
        caption="Port of Loading"
        minWidth={180}
        width={180}
        hidingPriority={7}
      />
      <Column
        dataField="portOfDestination"
        caption="Port of Destination"
        minWidth={180}
        width={180}
        hidingPriority={6}
      />
      <Column
        dataField="transitId"
        caption="Transit"
        minWidth={180}
        width={180}
        hidingPriority={1}
      />
      <Column
        dataField="despatchDate"
        caption="Despatched Date"
        minWidth={180}
        width={180}
        hidingPriority={2}
        dataType="datetime"
        format={FORMAT_DATE}
      />
      <Column
        dataField="estimatedArrival"
        caption="Estimated Arrival"
        minWidth={180}
        width={180}
        hidingPriority={3}
        dataType="datetime"
        format={FORMAT_DATE}
      />
      <Column
        dataField="arrivalDate"
        caption="Arrival Date"
        minWidth={180}
        width={180}
        hidingPriority={4}
        dataType="datetime"
        format={FORMAT_DATE}
      />
      <Column
        dataField="billOfLadingFile"
        caption="Bill of Lading File"
        minWidth={40}
        width={40}
        hidingPriority={5}
        allowFiltering={false}
        cellRender={onRenderCell}
        alignment="center"
      />
    </DataGrid>
  );
};
