export const columnsDescription = [
  {
    id: 1,
    text: 'Component PartType Description (required)',
  },
  {
    id: 2,
    text: 'Fault Description (required)',
  },
  {
    id: 3,
    text: 'Sub Fault Description (optional: leave empty if not required)',
  },
  {
    id: 4,
    text: 'Size Description (optional: leave empty if not required)',
  },
  {
    id: 5,
    text: 'Count Description (optional: leave empty if not required)',
  },
  {
    id: 6,
    text: 'Action Description (required)',
  },
  {
    id: 7,
    text: 'Severity Description (optional: leave empty if not required)',
  },
];
