import styled from 'styled-components';

export const TableWrapper = styled.div`
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  td {
    padding: 5px;
  }

  tr:first-of-type {
    td {
      font-weight: bold;
    }
  }
`
