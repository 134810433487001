import React, { FC, useState } from 'react';
import { Button, Popup, NumberBox, Form, Row, Col, notify } from '@/ui';
import { useTranslate } from '@/translations';
import { useCreateInspectionsVehiclesFlatRateCode } from '@/api/Inspections/hooks/useInspectionsVehicles';

import { NumberBoxWrapper } from './CreateFlatRateCodePopup.styled';
import { CreateFlatRateCodeProps } from './types';

type OnChange = {
  target: {
    value: string;
  };
};

export const CreateFlatRateCodePopup: FC<CreateFlatRateCodeProps> = ({
  isPopupVisible,
  togglePopup,
  onSubmitCb,
}) => {
  const { t } = useTranslate();
  const { mutateAsync: mutateAsyncCreateFRCode } =
    useCreateInspectionsVehiclesFlatRateCode();

  const [frCode, setFrCode] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [cost, setCost] = useState<number>(0);

  const onFrCodeChange = ({ target: { value } }: OnChange) => setFrCode(value);

  const onDescriptionChange = ({ target: { value } }: OnChange) =>
    setDescription(value);

  const onCostChange = ({
    event: {
      target: { value },
    },
  }: // eslint-disable-next-line
  any) => {
    // remove currency symbol and white spaces
    const formattedValue = value.slice(1).trim();
    setCost(Number(formattedValue));
  };

  const handleCloseForm = () => {
    togglePopup(false);
    setFrCode('');
    setDescription('');
    setCost(0);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const body = {
      flatRate: frCode,
      description,
      price: cost,
    };

    try {
      const { data: id } = await mutateAsyncCreateFRCode(body);

      onSubmitCb?.({
        ...body,
        id,
      });
      handleCloseForm();
    } catch (error) {
      console.log('error: ', error);
      notify(
        {
          // @ts-ignore
          message: error.Message || 'Something went wrong...',
          position: {
            my: 'center top',
            at: 'center top',
          },
        },
        'error',
        3000,
      );
    }
  };

  return (
    <Popup
      visible={isPopupVisible}
      onHiding={handleCloseForm}
      title={t('createFrCode')}
      position="center"
      minWidth="320"
      maxWidth="400"
      height="auto"
    >
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="frCode">
          <Form.Label column sm="3">
            {t('frCode')}
          </Form.Label>
          <Col sm="9">
            <Form.Control value={frCode} onChange={onFrCodeChange} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="description">
          <Form.Label column sm="3">
            {t('description')}
          </Form.Label>
          <Col sm="9">
            <Form.Control
              as="textarea"
              rows={5}
              value={description}
              onChange={onDescriptionChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="cost">
          <Form.Label column sm="3">
            {t('cost')}
          </Form.Label>
          <Col sm="9">
            <NumberBoxWrapper>
              <Form.Control
                as={NumberBox}
                min={0}
                format="£ #,##0.##"
                value={cost}
                onChange={onCostChange}
              />
            </NumberBoxWrapper>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="d-flex">
          <Button type="submit">{t('create')}</Button>
        </Form.Group>
      </Form>
    </Popup>
  );
};
