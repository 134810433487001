import { Damage, Inspection } from '@/types';

export interface DamageDataGridProps {
  inspection: Inspection;
  dataSource: Damage[];
}

export enum AuthorizationStatus {
  Default,
  Approved,
  Rejected,
}
