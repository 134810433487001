import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import { ChartTitle, Export, Legend, Series, Subtitle } from '@/ui';
import { useTranslate } from '@/translations';
import { labelBackgroundColor } from '@/utils/helpers';
import { PipelineChartProps } from './types';

export const PipelineChart: FC<PipelineChartProps> = () => {
  const { t } = useTranslate();
  const { primaryChartColor } = useTheme();

  return (
    <>
      <ChartTitle text={t('pipeline')}>
        <Subtitle text={t('byNumberOfVehicles')} />
      </ChartTitle>
      <Series
        valueField="noOfItems"
        argumentField="graphDesc"
        name={t('pipeline')}
        type="bar"
        color={primaryChartColor}
        label={labelBackgroundColor(primaryChartColor)}
      />
      <Export enabled />
      <Legend verticalAlignment="bottom" horizontalAlignment="center" />
    </>
  );
};
