import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../queryKeys';
import { vin } from '../Vin';

export const useVinPositions = (selectedVin?: string) =>
  useQuery(
    [QUERY_KEYS.vesselPositions, selectedVin],
    async () => (await vin.getVinPositions(selectedVin)).data,
    {
      enabled: !!selectedVin,
    },
  );
