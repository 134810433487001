import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../queryKeys';
import { roles } from '../Roles';

interface QueryOptions {
  enabled?: boolean;
}

export const useRoles = (options?: QueryOptions) =>
  useQuery(QUERY_KEYS.role, async () => (await roles.getRoles()).data, options);

export const useGBARoles = (options?: QueryOptions) =>
  useQuery(
    QUERY_KEYS.role,
    async () => (await roles.getGBARoles()).data,
    options,
  );
