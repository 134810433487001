import React from 'react';
import { useParams } from 'react-router-dom';
import {
  useInspectionsChecklistInterior,
  useInspectionsReportsInitial,
} from '@/api/Inspections';
import { Loader } from '@/ui/components/Loader';
import { checkResult } from '@/modules/Inspection/components/tabs/Documents/components/helpers';
import { Interior, ReportInitial, MechanicalsWeels } from '@/types';

import { MechanicalReport as MechanicalReportMarkup } from '../components/MechanicalReport';

export const MechanicalReport = () => {
  const { id } = useParams();

  const { isLoading: interiorIsLoading, data: interiorData } =
    useInspectionsChecklistInterior(Number(id));
  const { isLoading: isReportsInitialData, data: reportsInitialData } =
    useInspectionsReportsInitial(Number(id));

  const vehicleData = reportsInitialData?.entities?.[0] as ReportInitial;
  const interior = interiorData?.entities?.[0] as Interior;

  const checkItems = {
    interior: vehicleData?.mechanicalInspectionInteriorCheckList,
    electricHybrid: vehicleData?.mechanicalInspectionElectricCheckList,
    dynamicOperations: vehicleData?.mechanicalInspectionIstaCheckList,
    engineRunning: vehicleData?.mechanicalInspectionRunningEngineBayCheckList,
  };

  if (interiorIsLoading || isReportsInitialData) return <Loader />;

  const wheels = {
    frontLeftWheelInnerDepth:
    vehicleData?.mechanicalInspectionWheelDetails?.frontLeftWheel
      ?.wheelInnerDepth,
    frontLeftWheelMiddleDepth:
    vehicleData?.mechanicalInspectionWheelDetails?.frontLeftWheel
      ?.wheelMiddleDepth,
    frontLeftWheelOuterDepth:
    vehicleData?.mechanicalInspectionWheelDetails?.frontLeftWheel
      ?.wheelOuterDepth,
    frontRightWheelInnerDepth:
    vehicleData?.mechanicalInspectionWheelDetails
      ?.frontRightWheel?.wheelInnerDepth,
    frontRightWheelMiddleDepth:
    vehicleData?.mechanicalInspectionWheelDetails
      ?.frontRightWheel?.wheelMiddleDepth,
    frontRightWheelOuterDepth:
    vehicleData?.mechanicalInspectionWheelDetails
      ?.frontRightWheel?.wheelOuterDepth,
    rearLeftWheelInnerDepth:
    vehicleData?.mechanicalInspectionWheelDetails?.rearLeftWheel
      ?.wheelInnerDepth,
    rearLeftWheelMiddleDepth:
    vehicleData?.mechanicalInspectionWheelDetails?.rearLeftWheel
      ?.wheelMiddleDepth,
    rearLeftWheelOuterDepth:
    vehicleData?.mechanicalInspectionWheelDetails?.rearLeftWheel
      ?.wheelOuterDepth,
    rearRightWheelInnerDepth:
    vehicleData?.mechanicalInspectionWheelDetails?.rearRightWheel
      ?.wheelInnerDepth,
    rearRightWheelMiddleDepth:
    vehicleData?.mechanicalInspectionWheelDetails?.rearRightWheel
      ?.wheelMiddleDepth,
    rearRightWheelOuterDepth:
    vehicleData?.mechanicalInspectionWheelDetails?.rearRightWheel
      ?.wheelOuterDepth,
  } as MechanicalsWeels;

  const checkNSF = checkResult([
    wheels?.frontLeftWheelInnerDepth,
    wheels?.frontLeftWheelMiddleDepth,
    wheels?.frontLeftWheelOuterDepth,
  ]);

  const checkOSF = checkResult([
    wheels?.frontRightWheelInnerDepth,
    wheels?.frontRightWheelMiddleDepth,
    wheels?.frontRightWheelOuterDepth,
  ]);

  const checkNSR = checkResult([
    wheels?.rearLeftWheelInnerDepth,
    wheels?.rearLeftWheelMiddleDepth,
    wheels?.rearLeftWheelOuterDepth,
  ]);

  const checkOSR = checkResult([
    wheels?.rearRightWheelInnerDepth,
    wheels?.rearRightWheelMiddleDepth,
    wheels?.rearRightWheelOuterDepth,
  ]);

  return (
    <MechanicalReportMarkup
      vehicleData={vehicleData}
      interior={interior}
      checkItems={checkItems}
      wheels={wheels}
      checkNSF={checkNSF}
      checkOSF={checkOSF}
      checkNSR={checkNSR}
      checkOSR={checkOSR}
    />
  )
};
