import React, { FC, useCallback } from 'react';
import { DevButton, List, Popup } from '@/ui';
import { useTranslate } from '@/translations';
import { TitleWrapper } from '@/ui/components/PreviewModal/PreviewModal.styled';
import { Title as StyledTitle } from '@/ui/components/UploadDocumentModal/UploadDocumentModal.styled';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { faClose, Icon } from '@/ui/components/Icon';
import { InspectionStatusComponentProps } from './types';

export const InspectionStatusComponent: FC<InspectionStatusComponentProps> = ({
  dataSource,
  isPopupVisible,
  onTogglePopup,
  onChangeStatus,
}) => {
  const { t } = useTranslate();

  const titleRender = useCallback(
    () => (
      <TitleWrapper>
        <StyledTitle className="py-2">
          {t('changeInspectionStatus')}
        </StyledTitle>
        <StyledLinkContainer onClick={onTogglePopup}>
          <StyledLink>
            <Icon icon={faClose} scale={2} />
          </StyledLink>
        </StyledLinkContainer>
      </TitleWrapper>
    ),
    [t, onTogglePopup],
  );

  return (
    <Popup
      visible={isPopupVisible}
      onHiding={onTogglePopup}
      titleRender={titleRender}
      dragEnabled={false}
      closeOnOutsideClick
      maxWidth={480}
      height="auto"
    >
      <div>
        <List
          id="statuses"
          dataSource={dataSource}
          onItemClick={onChangeStatus}
        />

        <div className="mt-4 d-flex justify-content-end">
          <DevButton
            icon="clear"
            text={t('cancel')}
            stylingMode="outlined"
            onClick={onTogglePopup}
          />
        </div>
      </div>
    </Popup>
  );
};
