import { OrderSummary } from '@/types';
import { FormattedOrderSummaryData } from './types';

const findOrderRowIndex = (
  rows: FormattedOrderSummaryData[],
  item: OrderSummary,
) =>
  rows.findIndex(
    (row) =>
      row.consigneeCode === item.consigneeCode && row.country === item.country,
  );

export const formatOrderSummary = (orderSummaryEntities: OrderSummary[]) =>
  orderSummaryEntities.reduce((result, item) => {
    const orderRowIndex = findOrderRowIndex(result, item);

    const {
      companyId,
      consigneeCode,
      country,
      orderId,
      billOfLading,
      volume,
      portOfLoading,
      portOfDestination,
      transitId,
      despatchDate,
      estimatedArrival,
      arrivalDate,
      billOfLadingFile,
      movementId,
    } = item;
    if (orderRowIndex === -1) {
      return [
        ...result,
        {
          companyId,
          consigneeCode,
          country,
          orderId,
          billOfLading,
          volume,
          portOfLoading,
          portOfDestination,
          transitId,
          despatchDate,
          estimatedArrival,
          arrivalDate,
          billOfLadingFile,
          movementId,
          items: [item],
        },
      ];
    }
    const updatedResult = [...result];
    updatedResult[orderRowIndex].items.push(item);

    return updatedResult;
  }, [] as FormattedOrderSummaryData[]);
