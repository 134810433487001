import React, { FC } from 'react';
import { Card } from '@/ui';
import { useTranslate } from '@/translations';
import { getFormattedLabel } from '@/utils/helpers';

import { CardItemProps } from './types';

export const InspectionTabCard: FC<CardItemProps> = ({ header, data }) => {
  const { t } = useTranslate();

  return (
    <Card>
      <Card.Header className="text-muted fw-bold">{header}</Card.Header>
      <Card.Body>
        {Object.entries(data).map(([key, value]) => (
          <Card.Text
            as="div"
            key={key}
            className="dx-field m-0 d-flex align-items-center"
          >
            <div className="dx-field-label fw-bold">
              {getFormattedLabel(key)}:
            </div>
            <div
              className="dx-field-value text-muted"
              style={{ whiteSpace: 'pre-line' }}
            >
              {key === 'signature' && !!value ? (
                <Card.Img
                  className="border w-75"
                  src={value}
                  alt={value}
                  loading="lazy"
                />
              ) : (
                value || t('notApplicable')
              )}
            </div>
          </Card.Text>
        ))}
      </Card.Body>
    </Card>
  );
};
