import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { Title } from '@/ui/components/Title';
import { faLocationDot } from '@/ui/components/Icon';
import { PageContainer } from '@/ui/components/Page';
import { Loader } from '@/ui/components/Loader';
import { ServiceUnit } from '@/types';
import {
  useCreateServiceUnit,
  useServiceUnits,
  useUpdateServiceUnit,
} from '@/api/ServiceUnits';
import { useServiceUnitTypes } from '@/api/ServiceUnitTypes';
import { useServiceProviders } from '@/api/ServiceProviders';
import { useCountries } from '@/api/Countries';
import { formatUpdateCompanyData, formatCreateCompanyData } from './helpers';
import { TransportUnitsDataGrid } from './components/TransportUnitsDataGrid/TransportUnitsDataGrid';

export const AdminTransportUnits: FC = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();

  const { data: serviceUnitsData, isLoading: isServiceUnitsLoading } =
    useServiceUnits();
  const { mutate: createMutate, isLoading: isCreateLoading } =
    useCreateServiceUnit();
  const { mutate: updateMutate, isLoading: isUpdateLoading } =
    useUpdateServiceUnit();

  const { data: countriesData, isLoading: isCountriesLoading } = useCountries();
  const { data: serviceUnitTypesData, isLoading: isServiceUnitTypesLoading } =
    useServiceUnitTypes();
  const { data: serviceProvidersData, isLoading: isServiceProvidersLoading } =
    useServiceProviders();

  const handleCreateCompany = React.useCallback(
    (data: ServiceUnit) => {
      const formattedData = formatCreateCompanyData(data);

      createMutate(formattedData);
    },
    [createMutate],
  );

  const handleUpdateCompany = React.useCallback(
    (newData: Partial<ServiceUnit>, oldData: ServiceUnit) => {
      const formattedData = formatUpdateCompanyData(newData, oldData);

      updateMutate(formattedData);
    },
    [updateMutate],
  );

  if (
    isServiceUnitsLoading ||
    isCreateLoading ||
    isUpdateLoading ||
    isCountriesLoading ||
    isServiceUnitTypesLoading ||
    isServiceProvidersLoading
  )
    return <Loader />;

  return (
    <PageContainer fluid>
      <Title
        title={t('manageTransportUnits')}
        icon={faLocationDot}
        color={accent}
      />
      <TransportUnitsDataGrid
        onTransportUnitAdd={handleCreateCompany}
        onTransportUnitChanged={handleUpdateCompany}
        transportUnits={serviceUnitsData?.entities}
        serviceUnitTypes={serviceUnitTypesData?.entities}
        serviceProviders={serviceProvidersData?.entities}
        countries={countriesData?.entities}
      />
    </PageContainer>
  );
};
