import { AxiosPromise } from 'axios';
import { CountriesData } from '@/types';
import { Core, COUNTRIES } from '../Core';

type GetCountries = () => AxiosPromise<CountriesData>;

class Countries extends Core {
  getCountries: GetCountries = async () => this.get(COUNTRIES());
}

export const countries = new Countries();
