import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  inspections,
  useInspectionsVehicle,
  useInspectionsVehicleDamages,
} from '@/api/Inspections';
import { Loader } from '@/ui/components/Loader';
import { Inspection, Damage } from '@/types';

import { VehicleAppraisalReport as VehicleAppraisalReportMarkup } from '../components/VehicleAppraisalReport';

export const VehicleAppraisalReport = () => {
  const { id: inspectionId } = useParams();

  const { isLoading, data } = useInspectionsVehicle(Number(inspectionId));
  const { isLoading: isVehicleDamagesLoading, data: vehicleDamagesData } =
    useInspectionsVehicleDamages(Number(inspectionId));

  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const inspection = data?.entities?.[0] as Inspection;
  const vehicleDamages = useMemo(() => (vehicleDamagesData?.entities || []) as Damage[], [vehicleDamagesData]);

  useEffect(() => {
    const damagesIds = vehicleDamages.map((damage) => damage.id);

    damagesIds?.forEach(async (damageId) => {
      const { data: damagesData } = await inspections.getInspectionsDamagesImages(damageId);

      setImageUrls((prevState) => [
        ...prevState,
        ...(damagesData.entities || []).map(({ imageFileUrl }) => imageFileUrl)
      ]);
    });
  }, [vehicleDamages]);

  if (isLoading || isVehicleDamagesLoading) return <Loader />;

  const vehicleData = {
    regNumber: inspection.regNumber,
    model: inspection.model,
    gradeScore: inspection.cumulativeGradeScore,
    colour: inspection.colour,
    mileage: inspection.mileage,
    vin: inspection.vin,
    inspectionDateTime: inspection.inspectionDateTime,
  };

  return (
    <VehicleAppraisalReportMarkup
      vehicleData={vehicleData}
      vehicleDamages={vehicleDamages}
      imageUrls={imageUrls}
    />
  );
};
