import { AxiosPromise } from 'axios';
import { LocationTypesData } from '@/types';
import { Core, LOCATION_TYPES } from '../Core';

type GetLocationTypes = () => AxiosPromise<LocationTypesData>;

class LocationTypes extends Core {
  getLocationTypes: GetLocationTypes = async () => this.get(LOCATION_TYPES());
}

export const locationTypes = new LocationTypes();
