import React, { FC, memo } from 'react';
import { Col, Row } from '@/ui';
import { Link } from 'react-router-dom';
import { faEye, faUpload, Icon } from '@/ui/components/Icon';
import { useGetPathToDocumentManagement } from '@/hooks/useGetPathToDocumentManagement';
import { AppEntities } from '@/types';
import { useTranslate } from '@/translations';
import { Loader } from '@/ui/components/Loader';
import { localDateFormatted } from '@/utils/helpers';

import { PassportDetailProps } from './types';

export const PassportDetails: FC<PassportDetailProps> = memo(
  ({ passportData, isLoading }) => {
    const { t } = useTranslate();

    const { getDocumentManagementPath, hasAdminAccess } =
      useGetPathToDocumentManagement();

    if (isLoading) return <Loader />;

    if (!passportData) return <h1>{t('noData')}</h1>;

    const filesPreviewLink = (
      <>
        <Link
          to={getDocumentManagementPath({
            entityReference: passportData.regNumber,
          })}
        >
          <Icon icon={faEye} />
        </Link>
        {hasAdminAccess && (
          <Link
            to={getDocumentManagementPath({
              modal: 'uploadDocument',
              entity: AppEntities.Vehicle,
              entityReference: passportData.regNumber,
            })}
            style={{ marginLeft: '8px' }}
          >
            <Icon icon={faUpload} />
          </Link>
        )}
      </>
    );

    const data = [
      {
        label: t('vinColon').toUpperCase(),
        value: passportData.vin,
      },
      {
        label: t('vehicleColon'),
        value: passportData.model,
      },
      {
        label: t('colourColon'),
        value: passportData.colour,
      },
      {
        label: t('regNumber'),
        value: passportData.regNumber,
      },
      {
        label: t('site'),
        value: passportData.locationDesc,
      },
      {
        label: t('locationColon'),
        value: passportData.location,
      },
      {
        label: t('statusColon'),
        value: passportData.vinStatus,
      },
      {
        label: t('statusDescriptionColon'),
        value: passportData.vinStatusDesc,
      },
      {
        label: t('manufacturedDateColon'),
        value: passportData.manufactureDate
          ? localDateFormatted(passportData.manufactureDate)
          : null,
      },
      {
        label: t('transportCompanyColon'),
        value: passportData.providerName,
      },
      {
        label: t('arrivalDestination'),
        value: passportData.locationDesc,
      },
      {
        label: t('arrivalPostCode'),
        value: passportData.locationPostCode,
      },
      {
        label: t('fleetCode'),
        value: passportData.fleetCode,
      },
      {
        label: t('filesColon'),
        value: filesPreviewLink,
      },
    ];

    return (
      <>
        {data.map(({ label, value }) => (
          <Row key={label} className="mb-4 mt-4">
            <Col className="grey-bold" lg={4}>
              {label}
            </Col>
            <Col lg={8}>{value}</Col>
          </Row>
        ))}
      </>
    );
  },
);
