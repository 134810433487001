import { Tenant } from '../Tenants/Tenants';
import { Role } from '../Roles/Role';

export interface User {
  id: number;
  inspectorId: number;
  guid: string;
  email: string;
  status: string;
  role: Role;
  displayName: string;
  dateInvited: string;
  dateCreated: string;
  tenant: Tenant;
}

export interface CreateUser {
  displayName: string;
  role: number;
  email: string;
  inspectorId?: number;
}

export interface CreateUserInTenant extends CreateUser {
  tenantId: number;
}

export interface UpdateUser {
  id: number;
  role: {
    isChanged: boolean;
    value: number;
  };
  displayName: {
    isChanged: boolean;
    value: string;
  };
}

export interface DeleteUser {
  userId: number;
}

export interface DeleteGBAUser {
  gbaUserId: number;
}

export interface UserAssignment {
  userId: number;
  tenantId: number;
}

export interface UsersData {
  entities: User[];
}

export enum Status {
  created = 'Created',
  invited = 'Invited',
  active = 'Active',
}
