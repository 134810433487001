import { AxiosPromise } from 'axios';
import { ServiceProvidersData } from '@/types';
import { Core, SERVICE_PROVIDERS } from '../Core';

type GetServiceProviders = () => AxiosPromise<ServiceProvidersData>;

class ServiceProviders extends Core {
  getServiceProviders: GetServiceProviders = async () =>
    this.get(SERVICE_PROVIDERS());
}

export const serviceProviders = new ServiceProviders();
