import styled, { css } from 'styled-components';

const lightRed = 'rgba(255, 0, 0, 0.1)';

const failedStyles = css`
  .accordion-button {
    background: ${lightRed};
    color: black;
  }

  .accordion-button:focus {
    box-shadow: 0 0 0 .25rem ${lightRed};
  }

  .accordion-body {
    background: ${lightRed};
  }
`;

export const AccordionItemWrapper = styled.div<{ isFailed: boolean }>`
  ${({ isFailed }) => isFailed && failedStyles};
`;
