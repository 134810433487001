import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { AppRoles } from '@/types';

type UseGetUserRole = () => Record<
  'GbaSuperAdmin' | 'GbaAdmin' | 'GbaMember' | 'Admin' | 'Member',
  boolean
>;

export const useGetUserRole: UseGetUserRole = () => {
  const idTokenClaims = useIdTokenClaims();
  const userRoles = idTokenClaims?.roles || [];

  return {
    GbaSuperAdmin: userRoles.includes(AppRoles.GbaSuperAdmin),
    GbaAdmin: userRoles.includes(AppRoles.GbaAdmin),
    GbaMember: userRoles.includes(AppRoles.GbaMember),
    Admin: userRoles.includes(AppRoles.Admin),
    Member: userRoles.includes(AppRoles.Member),
  };
};
