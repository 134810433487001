export const checkResult = (
  mechanicalsWheels: (string | undefined | null)[],
) => {
  const resultCount: number = mechanicalsWheels?.reduce(
    (acc: number, val: string | undefined | null) => acc + (val ? 1 : 0),
    0,
  );

  if (resultCount === 3) {
    return 'Pass';
  }

  if (resultCount === 0) {
    return 'Not Applicable';
  }

  return 'Fail';
};
