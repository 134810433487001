import { useState } from 'react';

export type ExpandedRows = Record<number, boolean>;

export type DataGridExpandedStateResult = {
  expandedRows: ExpandedRows;
  onRowExpanded: (value: { key: { id: number } }) => void;
  onRowCollapsed: (value: { key: { id: number } }) => void;
};

type UseDataGridExpandedState = () => DataGridExpandedStateResult;

export const useDataGridExpandedState: UseDataGridExpandedState = () => {
  const [expandedRows, setExpandedRows] = useState<ExpandedRows>({});

  const onRowExpanded: DataGridExpandedStateResult['onRowExpanded'] = ({
    key: { id },
  }) => {
    if (expandedRows[id] || !id) return;
    setExpandedRows({ ...expandedRows, [id]: true });
  };

  const onRowCollapsed: DataGridExpandedStateResult['onRowCollapsed'] = ({
    key: { id },
  }: {
    key: { id: number };
  }) => {
    if (!expandedRows[id] || !id) return;
    setExpandedRows({ ...expandedRows, [id]: false });
  };

  return {
    expandedRows,
    onRowExpanded,
    onRowCollapsed,
  };
};
