import { AxiosPromise } from 'axios';
import {
  ServiceUnitsData,
  UpdateServiceUnit,
  CreateServiceUnit,
} from '@/types';
import { Core, SERVICE_UNITS } from '../Core';

type GetServiceUnits = () => AxiosPromise<ServiceUnitsData>;
type UpdateServiceUnits = (data: UpdateServiceUnit) => AxiosPromise<boolean>;
type CreateServiceUnits = (data: CreateServiceUnit) => AxiosPromise<boolean>;

class ServiceUnits extends Core {
  getServiceUnits: GetServiceUnits = async () => this.get(SERVICE_UNITS());

  updateServiceUnits: UpdateServiceUnits = async (data) =>
    this.patch<UpdateServiceUnit, AxiosPromise<boolean>>(SERVICE_UNITS(), data);

  createServiceUnits: CreateServiceUnits = async (data) =>
    this.put<CreateServiceUnit, AxiosPromise<boolean>>(SERVICE_UNITS(), data);
}

export const serviceUnits = new ServiceUnits();
